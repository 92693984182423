import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  FooterProfileIcon,
  LogoutIcon,
  RxIcon,
  SetAvailabilityIcon,
  TransectionsIcon,
} from '../../../atoms';
import { profileMobilePage_style } from './style';

function ProfileMobilePage(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const navigate = useNavigate();

  const data = [
    {
      name: 'View profile',
      icon: <FooterProfileIcon rootStyle={{ height: '16px', color: '#000' }} />,
      path: '/profile',
    },
    {
      name: 'Set availability',
      icon: (
        <SetAvailabilityIcon
          rootStyle={{ height: '16px', px: '2px', color: '#000' }}
        />
      ),
      path: '/availability',
    },
    {
      name: 'Rx group',
      icon: <RxIcon rootStyle={{ height: '16px', color: '#000' }} />,
      path: '/rxgroup',
    },
    {
      name: 'Transactions',
      icon: (
        <TransectionsIcon
          rootStyle={{ height: '16px', px: '2px', color: '#000' }}
        />
      ),
      path: '/transactions',
    },
    {
      name: 'Logout',
      icon: <LogoutIcon rootStyle={{ height: '16px' }} />,
      path: '/login',
    },
  ];

  const onCardClick = (path) => {
    if (path !== '/login') {
      navigate(path);
    } else {
      toast.success('Signed Out Successfully');
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        ...profileMobilePage_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {data?.map((val, i) => (
        <Box
          key={i}
          sx={profileMobilePage_style?.cardRootSx}
          onClick={() => onCardClick(val?.path, i)}
        >
          <Box alignSelf="center">
            <Typography
              fontSize={14}
              fontWeight={500}
              color={val?.name === 'Logout' ? '#F44F5A' : '#000 '}
            >
              {val?.name}
            </Typography>
          </Box>
          <Box
            alignSelf="center"
            width={20}
            display="flex"
            justifyContent="center"
          >
            {val?.icon}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

ProfileMobilePage.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ProfileMobilePage };
