/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { ConnectIcon, UserProfileIcon } from '@atoms';
import { doctorRoutes } from '@hc/routes';
import { useDoctorOnboarding, useDoctorProfile, useViewPort } from '@hc/store';
import Waves from '@hc/ui/assets/Wave.svg';
import { BasicInfo } from '@hc/ui/components/doctor/basicInfo';
import { getAuthData } from '@hc/utils/helperFunctions';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ProfessionalInfoIcon } from '../../../atoms';
import { DoctorTitleCard } from '../doctorTitleCard';
import { GoogleConnect } from '../onboarding/googleConnect';
import { Stepper } from '../stepper';
import { stepperForm_style } from './style';
import { ProfessionalInfo } from '../professionalInfo';

export function StepperForm(props) {
  const { logo } = props;
  const navigate = useNavigate();
  const data = getAuthData();

  const { activeIndex, handleBack, nextActive, handleNext, doctor } =
    useDoctorOnboarding();

  const { upsertProfileBasicInfo, isIamValided, isDocterValidToSave } =
    useDoctorProfile((state) => ({
      profile: state.profile,
      upsertProfileBasicInfo: state.upsertProfileBasicInfo,
      isIamValided: state.isIamValided,
      isDocterValidToSave: state.isDocterValidToSave,
    }));

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const [stepsCompleted, setstepsCompleted] = useState([]);

  const onNext = async (tab) => {
    if (tab === 0) {
      if (isIamValided()) {
        handleNext();
        setstepsCompleted([tab]);
      }
    } else if (tab === 1) {
      if (isDocterValidToSave()) {
        handleNext();
        setstepsCompleted([...stepsCompleted, tab]);
      }
    } else if (tab === 2) {
      setstepsCompleted([...stepsCompleted, tab]);
      if (isIamValided()) {
        const responseData = await upsertProfileBasicInfo(
          data?.user_id,
          doctor,
        );

        if (responseData === 200) {
          navigate(doctorRoutes?.home);
        }
      }
    }
  };

  const onBack = () => {
    handleBack();
    setstepsCompleted([...stepsCompleted.splice(stepsCompleted.lenth - 1, 1)]);
  };

  // Function Render component
  function stepRenderer(index) {
    switch (index) {
      case 0:
        return (
          <Box
            sx={
              isMobilePort
                ? stepperForm_style.rightMobileSx
                : stepperForm_style.rightSx
            }
          >
            <DoctorTitleCard
              title="Update Basic Info"
              description={doctor?.email ?? ''}
              rootStyle={isMobilePort ? { mt: 13 } : ''}
              nextStyle={isMobilePort ? { zIndex: '1' } : ''}
              onNext={onNext}
              onBack={onBack}
              activeIndex={activeIndex}
              activeBack={activeIndex > 0 ? true : false}
              activeNext={
                !isMobilePort
                  ? activeIndex === 0 && nextActive === false
                    ? true
                    : false
                  : false
              }
            />

            <Box sx={stepperForm_style.dataSx}>
              <BasicInfo />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box
            sx={
              isMobilePort
                ? stepperForm_style.rightMobileSx
                : stepperForm_style.rightSx
            }
            mt={isMobilePort ? '30px' : ''}
            height="100vh"
          >
            <DoctorTitleCard
              title="Update Professional Info"
              description={doctor?.email ?? ''}
              onNext={onNext}
              rootStyle={isMobilePort ? { mt: 10 } : ''}
              nextStyle={isMobilePort ? { zIndex: '1' } : ''}
              activeBack={activeIndex > 0 ? true : false}
              onBack={onBack}
              activeIndex={activeIndex}
              activeNext={
                !isMobilePort ? (activeIndex === 1 ? true : false) : false
              }
            />

            <Box sx={stepperForm_style.dataSx}>
              <ProfessionalInfo />
            </Box>
          </Box>
        );

      case 2:
        return (
          <Box
            sx={
              isMobilePort
                ? stepperForm_style.rightMobileSx
                : stepperForm_style.rightSx
            }
            mt={isMobilePort ? '30px' : ''}
            height="100vh"
          >
            <DoctorTitleCard
              title="Connect Google Account"
              description={doctor?.email ?? ''}
              onNext={onNext}
              rootStyle={isMobilePort ? { mt: 10 } : ''}
              nextStyle={isMobilePort ? { zIndex: '1' } : ''}
              activeBack={activeIndex > 0 ? true : false}
              onBack={onBack}
              activeIndex={activeIndex}
              activeNext={
                !isMobilePort ? (activeIndex === 2 ? true : false) : false
              }
            />

            <Box sx={stepperForm_style.dataSx}>
              <GoogleConnect />
            </Box>
          </Box>
        );
      default:
        break;
    }
  }

  return (
    <Box sx={stepperForm_style.rootSx} container>
      {/* left Side */}
      {!isMobilePort && (
        <Box sx={stepperForm_style.leftSx}>
          <Box sx={stepperForm_style.TotalLogoSx}>
            <Box sx={stepperForm_style.logoSx}>{logo}</Box>
            <Box sx={{ display: 'grid', ml: 1 }}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                Health Circles
              </Typography>
            </Box>
          </Box>
          <Stack sx={stepperForm_style.totalStepperSx}>
            <Box
              px={2}
              data-active={activeIndex === 2 ? true : false}
              id="stepper"
            >
              <Stepper
                vertical
                stepsCompleted={stepsCompleted}
                activeIndex={activeIndex}
                StepperFunc={[
                  {
                    stepperIcon: (
                      <UserProfileIcon rootStyle={{ color: '#fff' }} />
                    ),
                    step: 'Step 1',
                    stepDescription: 'Basic Info',
                  },
                  {
                    stepperIcon: <ProfessionalInfoIcon />,
                    step: 'Step 2',
                    stepDescription: 'Professional Info',
                  },
                  {
                    stepperIcon: <ConnectIcon />,
                    step: 'Step 3',
                    stepDescription: 'Connect Google Account',
                  },
                ]}
              />
            </Box>
            <Box>
              <img src={Waves} alt="Waves" width="100%" />
            </Box>
          </Stack>
        </Box>
      )}

      {isMobilePort && (
        <Box display="flex" justifyContent="">
          <Box sx={{ ...stepperForm_style.stepperbarSx }}>
            <Typography color="#fff">
              {' '}
              <Stepper
                horizontal
                stepsCompleted={stepsCompleted}
                activeIndex={activeIndex}
                StepperFunc={[
                  // {
                  //   stepperIcon: <LockPassword />,
                  //   step: 'step 1',
                  //   stepDescription: 'Set New Password',
                  // },
                  {
                    stepperIcon: (
                      <UserProfileIcon rootStyle={{ color: '#fff' }} />
                    ),
                    step: 'Step 1',
                    stepDescription: 'Basic Info',
                  },
                  {
                    stepperIcon: <ProfessionalInfoIcon />,
                    step: 'Step 2',
                    stepDescription: 'Professional Info',
                  },
                  {
                    stepperIcon: <ConnectIcon />,
                    step: 'Step 3',
                    stepDescription: 'Google Account',
                  },
                ]}
              />
              {/* <CommonSteper step={1} icons={icons} stepText={steps} /> */}
            </Typography>
          </Box>
          <Box sx={{ ...stepperForm_style.footerNavBarSx }}>
            {activeIndex === 0 && (
              <Button onClick={() => onNext(activeIndex)} fullwidth>
                Next
              </Button>
            )}
            {activeIndex === 1 && (
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <Button variant="outlined" onClick={() => onBack()} fullwidth>
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Button onClick={() => onNext(activeIndex)} fullwidth>
                    Next
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      )}

      {/* Right Side */}
      {stepRenderer(activeIndex)}
    </Box>
  );
}
StepperForm.propTypes = {};
