import { Button, DialogModal, Input, MobileInput, ToggleButton } from '@atoms';
import { usePatientDetails } from '@hc/store';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { DateRangePicker } from '../../../atoms/datePicker';
import { addPatientUi_style } from './style';

function AddPatientUi(props) {
  const {
    className = '',
    rootStyle = {},
    isModalOpen,
    handleClose = () => {},
    isMobileResponsive,
    ...rest
  } = props;

  const options = [
    {
      name: 'Male',
      value: 'Male',
    },
    {
      name: 'Female',
      value: 'Female',
    },
    {
      name: 'Others',
      value: 'Others',
    },
  ];

  const dob = [
    {
      name: 'D.O.B',
      value: 'D.O.B',
    },
    {
      name: 'YRS',
      value: 'Years',
    },
  ];

  const PATTERN = /^[0-9]+(\.[0-9]+)?$/;
  // pattern.test(inputString);

  const { addNewPatient, getPatientData } = usePatientDetails(
    (state) => ({
      addNewPatient: state.addNewPatient,
      getPatientData: state.getPatientData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const [gendervalue, setGenderValue] = useState('Male');
  const [name, setName] = useState();
  const [age, setAge] = useState();
  const [selectedDateAge, setselectedDateAge] = useState(0);
  const [mobile, setMobile] = useState();
  const [agevalue, setAgeValue] = useState('D.O.B');
  const [ageDisplay, setAgeDisplay] = useState(false);
  const [isError, setIsError] = useState('');
  const [mobileErroText, setMobileErroText] = useState('');

  const fetchAge = (e) => {
    setAgeValue(e);
    if (e === 'Years') {
      setAgeDisplay(true);
    } else {
      setAgeDisplay(false);
    }
  };

  const clearState = () => {
    setName('');
    setAge('');
    setselectedDateAge('');
    setMobile('');
  };

  const handleDateChange = (val) => {
    setselectedDateAge(val);
  };
  const onAdd = async () => {
    let selectedAge;
    let obj;
    if (name) {
      if (gendervalue) {
        if (selectedDateAge || age) {
          if (!ageDisplay) {
            selectedAge =
              new Date(new Date() - new Date(selectedDateAge)).getFullYear() -
              1970;
          }
          if (age || selectedAge !== '') {
            if (mobile?.mobileCode) {
              if (mobile?.mobile) {
                if (mobile?.mobile?.length === 10) {
                  obj = {
                    name,
                    gender: gendervalue,
                    age: ageDisplay ? age : selectedAge,
                    country_code: mobile?.mobileCode,
                    mobile_no: mobile?.mobile,
                  };
                  const addPatientRes = await addNewPatient(obj);

                  if (addPatientRes) {
                    await getPatientData();
                    clearState();
                    handleClose();
                  }
                } else {
                  setIsError('mobile');
                  setMobileErroText(
                    'Please enter the 10 digits of Mobile number..'
                  );
                }
              } else {
                setIsError('mobile');
                setMobileErroText('Please enter the Mobile number..');
              }
            } else {
              setIsError('mobile');
              setMobileErroText('Please enter the Mobile number..');
            }
          } else {
            toast.error('Please enter the Age..');
            setIsError('age');
          }
        } else {
          toast.error('Please enter the Age..');
          setIsError('age');
        }
      } else {
        setIsError('gender');
      }
    } else {
      setIsError('Name');
    }
  };

  const setAgeFnc = (value, limit) => {
    if (value === '' || (value?.length === 1 && value === '0')) {
      setAge('');
    } else if (value?.length <= limit && PATTERN.test(value)) {
      setAge(value);
    }
  };

  return (
    <Box
      sx={{
        ...addPatientUi_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="ADD NEW PATIENT"
        topDivider
        bottomDivider
        dialogRootStyle={addPatientUi_style.dialogSx}
        titleStyle={addPatientUi_style.titleSx}
        isBackRequired={isMobileResponsive ? true : false}
        isCloseOut={isMobileResponsive ? false : true}
        isMobileResponsive={isMobileResponsive}
        content={
          <Box>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography gutterBottom fontWeight={600} mb={1.2}>
                      Name
                    </Typography>
                    <Input
                      id="name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                      isError={isError === 'Name' ? true : false}
                      helperText={
                        isError === 'Name' ? 'Please enter the Name..' : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom fontWeight={600} mb={1.2}>
                  Gender
                </Typography>
                <ToggleButton
                  options={options}
                  value={gendervalue}
                  onChange={(e) => {
                    setGenderValue(e);
                  }}
                  buttonactiveStyle={addPatientUi_style.toggleButtonActiveSx}
                  buttoninactiveStyle={
                    addPatientUi_style.toggleButtonInActiveSx
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom fontWeight={600} mb={1.2}>
                  Age
                </Typography>
                <Stack direction="row" spacing={2}>
                  <ToggleButton
                    options={dob}
                    value={agevalue}
                    onChange={fetchAge}
                    buttonactiveStyle={addPatientUi_style.toggleButtonActiveSx}
                    buttoninactiveStyle={
                      addPatientUi_style.toggleButtonInActiveSx
                    }
                  />
                  {ageDisplay ? (
                    <Input
                      id="age"
                      onChange={(e) => setAgeFnc(e.target.value, 3)}
                      type="text"
                      endAdornment="yrs"
                      value={age}
                      isError={isError === 'age' ? true : false}
                      helperText={
                        isError === 'age' ? 'Please enter the Age..' : ''
                      }
                      textFieldStyle={{
                        '& .MuiOutlinedInput-input': {
                          width: '100%',
                          fontWeight: 600,
                          height: '32px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderRadius: '8px',
                            border: '1.5px solid',
                            borderColor: 'border.main',
                          },
                          '&:hover fieldset': {
                            borderColor: 'border.hover',
                          },
                          '&.Mui-disabled fieldset': {
                            borderColor: 'border.hover',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'border.focus',
                          },
                        },
                        '& .MuiFormHelperText-root': {
                          mx: 0,
                        },
                        '& input::placeholder': {
                          fontSize: '14px',
                          fontWeight: 400,
                        },
                      }}
                    />
                  ) : (
                    <DateRangePicker
                      peekNextMonth
                      showYearDropdown
                      startDate={selectedDateAge}
                      showMonthDropdown
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      maxDate={new Date()}
                    />
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom fontWeight={600} mb={1.2}>
                  Mobile Number
                </Typography>
                <MobileInput
                  isError={isError === 'mobile' ? true : false}
                  onChange={setMobile}
                  value={mobile}
                  helperText={isError === 'mobile' ? mobileErroText : ''}
                />
              </Grid>
            </Grid>
          </Box>
        }
        actions={
          <Grid
            p={1}
            container
            display="flex"
            justifyContent={isMobileResponsive ? 'space-around' : 'end'}
            spacing={2}
          >
            {isMobileResponsive && (
              <Grid item xs={6}>
                <Button onClick={handleClose} sx={addPatientUi_style.buttonSx}>
                  CANCLE
                </Button>
              </Grid>
            )}
            <Grid item xs={isMobileResponsive ? 6 : 4}>
              <Button onClick={onAdd}>ADD PATIENT</Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={handleClose}
      />
    </Box>
  );
}

AddPatientUi.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  isMobileResponsive: PropTypes.bool,
};

export { AddPatientUi };
