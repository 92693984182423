/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable brace-style */
/* eslint-disable react/jsx-filename-extension */
import { queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useDoctorReport = create((set, get) => ({
  reportState: {
    uploadedData: [],
    uploadedDataInitialData: [],
    caseSummaryData: [],
    caseSummaryDataInitialData: [],
    viewReportData: [],
    tabIndex: '1',
    search: '',
    report_type: '',
  },

  getPatientReport: async (patientData) => {
    try {
      const { reportState } = get();
      const { search, report_type, tabIndex } = reportState;
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        '/doctor/view-summary',
        'post',
        {
          patient_profile_id: patientData?.user_profile_id ?? '',
          search: search ?? '',
          report_type: report_type ?? '',
          appointment_id: patientData?.appointment_id ?? '',
        },
      ]);

      return set(() => ({
        loading: false,
        reportState: {
          ...reportState,
          uploadedData: data?.uploaded.length > 0 ? data?.uploaded : [],
          uploadedDataInitialData:
            data?.uploaded.length > 0 ? data?.uploaded : [],
          caseSummaryData:
            data?.case_summary.length > 0 ? data?.case_summary : [],
          caseSummaryDataInitialData:
            data?.case_summary.length > 0 ? data?.case_summary : [],
          viewReportData:
            tabIndex === '1'
              ? data?.uploaded.length > 0
                ? data?.uploaded[0].upload?.upload_data
                : ''
              : data?.case_summary.length > 0
              ? data?.case_summary[0].upload
              : '',
        },
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  setViewReportData: (value) => {
    const { reportState } = get();
    const { tabIndex, uploadedData, caseSummaryData } = reportState;
    let uploadedDataCopy = JSON.parse(JSON.stringify(uploadedData));
    let caseSummaryDataCopy = JSON.parse(JSON.stringify(caseSummaryData));
    let uploadData;
    if (tabIndex === '1') {
      uploadedDataCopy?.forEach((object) => {
        if (object?.id === value?.id) {
          uploadData = object?.upload?.upload_data;
        }
      });
    } else {
      caseSummaryDataCopy?.forEach((object) => {
        if (object?.id === value?.id) {
          uploadData = [object];
        }
      });
    }

    set(() => ({
      reportState: {
        ...reportState,
        viewReportData: uploadData,
      },
    }));
  },

  setSeachFilter: (value, key) => {
    const { reportState } = get();
    if (reportState?.tabIndex === '1') {
      let uploadedDataInitialDataCopy = JSON.parse(
        JSON.stringify(reportState?.uploadedDataInitialData)
      );
      let Arr;
      if (key === 'search') {
        Arr = uploadedDataInitialDataCopy?.filter(function (val) {
          return val.report_name.includes(value);
        });
      } else if (key === 'filter') {
        if (value) {
          Arr = uploadedDataInitialDataCopy?.filter(function (val) {
            return val?.master_report_type?.id === value;
          });
        } else {
          Arr = reportState?.uploadedDataInitialData;
        }
      }

      set(() => ({
        loading: false,
        reportState: {
          ...reportState,
          uploadedData: Arr.length > 0 ? Arr : [],
          viewReportData: Arr.length > 0 ? Arr[0].upload?.upload_data : [],
        },
      }));
    } else {
      let caseSummaryDataInitialDataCopy = JSON.parse(
        JSON.stringify(reportState?.caseSummaryDataInitialData)
      );

      let Arr;

      if (key === 'search') {
        Arr = caseSummaryDataInitialDataCopy?.filter(function (val) {
          return val.doctor_profile?.first_name.includes(value);
        });
      }
      // else if (key === 'filter') {
      //   Arr = caseSummaryDataInitialDataCopy?.filter(function (val) {
      //     return val?.master_report_type?.id.includes(value);
      //   });
      // }
      let viewReportDataArr = [];
      let obj = Arr[0];
      viewReportDataArr.push(obj);

      set(() => ({
        reportState: {
          ...reportState,
          caseSummaryData: Arr,
          viewReportData: viewReportDataArr,
        },
      }));
    }
  },

  onTabChange: (val) => {
    const { reportState } = get();
    if (val === '1') {
      let uploadedDataInitialDataCopy = JSON.parse(
        JSON.stringify(
          reportState?.uploadedDataInitialData.length > 0
            ? reportState?.uploadedDataInitialData
            : ''
        )
      );

      set(() => ({
        reportState: {
          ...reportState,
          viewReportData:
            uploadedDataInitialDataCopy[0].upload?.upload_data.length > 0
              ? uploadedDataInitialDataCopy[0].upload?.upload_data
              : [],
        },
      }));
    } else if (val === '2') {
      let caseSummaryDataInitialDataCopy = JSON.parse(
        JSON.stringify(
          reportState?.caseSummaryDataInitialData.length > 0
            ? reportState?.caseSummaryDataInitialData[0]
            : ''
        )
      );

      set(() => ({
        reportState: {
          ...reportState,
          viewReportData: caseSummaryDataInitialDataCopy
            ? [caseSummaryDataInitialDataCopy]
            : [],
        },
      }));
    }
  },

  updateReportStore: (key, value) => {
    const { reportState } = get();
    set({
      reportState: {
        ...reportState,
        [key]: value,
      },
    });
  },
  loading: null,
  error: null,
}));
