export const stepperForm_style = {
  rootSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  footerNavBarSx: {
    position: 'fixed',
    bottom: 0,
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
    display: 'flex',
    justifyContent: 'space-between',
    px: 3,
    py: 3,
    boxSizing: 'border-box',
    bgcolor: 'common.white',
    borderColor: 'border.main',
    boxShadow: '0px -2px 23px #00000014',
  },
  stepperbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    // bgcolor: 'primary.light',
    height: 115,
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: 0,
    zIndex: '5',
    left: 0,
    alignSelf: 'center',
    right: 0,
    bgcolor: '#EBFAF7',
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  TotalLogoSx: {
    display: 'flex',
    alignItems: 'center',
    pt: 2,
    px: 2,
  },
  leftSx: {
    backgroundColor: '#EBFAF7',
    height: '100vh',
    borderRadius: '8px',
  },
  logoSx: { height: '25px' },
  totalStepperSx: {
    pt: 5.5,
    height: '97%',
    justifyContent: 'space-between',
  },
  rightSx: {
    px: 3,
    width: '80%',
    maxHeight: '100vh',
    overflow: 'auto',
  },

  rightMobileSx: {
    width: '100%',
    maxHeight: '100vh',
    overflow: 'auto',
    pb: 12,
  },
  rightTextSx: {
    color: '#0F0B11',
    fontSize: '20px',
    fontWeight: 600,
  },
  rightSubTextSx: {
    color: '#18574C7A',
    fontSize: '14px',
    fontWeight: 600,
  },
  nextSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    position: 'sticky',
    top: 0,
    bgcolor: 'white',
    zIndex: 99,
    pb: 5,
  },
  iconNextSx: {
    fontSize: '16px',
    fontWeight: 600,
  },
  dataSx: {},
};
