/* eslint-disable no-restricted-syntax */
import { Dropdown } from '@atoms';
import { doctorRoutes } from '@hc/routes';
import { useViewPort } from '@hc/store';
import { usePatientDetails } from '@hc/store/doctor/patient';
import { EmptyPatients, FloatingAddIcon } from '@hc/ui/atoms';
import { ClinicalEmptyState, DoctorModuleSkeleton } from '@hc/ui/components';
import { Box } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookappoinmentDialog } from '../bookappoinmentDialog';
import { PatientCard } from '../patientCard';
import { ReferDialog } from '../referDialog';
import { patientViewCard_style } from './style';

function PatientViewCard(props) {
  const {
    className = '',
    rootStyle = {},
    onAddPatient = () => {},
    setTabIndex = () => {},
    ...rest
  } = props;

  const navigate = useNavigate();

  // store Data
  const {
    patientState,
    updatePatientState,
    specialityMasterData,
    clearDoctorSlots,
    loading,
  } = usePatientDetails(
    (state) => ({
      specialityMasterData: state.specialityMasterData,
      updatePatientState: state.updatePatientState,
      patientState: state.patientState,
      clearDoctorSlots: state.clearDoctorSlots,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { patientData, filter } = patientState;
  const [isBookAppoinment, setIsBookAppoinment] = useState(false);
  const [isRefer, setIsRefer] = useState(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [specialityData, setSpecialityData] = useState([]);

  // Filter Option
  const filtetOption = [
    {
      value: 'A-Z',
      label: 'A-Z',
    },
    {
      value: 'Z-A',
      label: 'Z-A',
    },
  ];

  // A-z & Z-A Func
  const FilterPatientData = (val) => {
    updatePatientState('filter', val);
    if (val === 'A-Z') {
      const AZ = patientData?.sort((a, b) =>
        a.patient_profile.name.localeCompare(b.name)
      );
      updatePatientState('patientData', AZ);
    } else if (val === 'Z-A') {
      const ZA = patientData?.sort((a, b) =>
        b.patient_profile.name.localeCompare(a.name)
      );
      updatePatientState('patientData', ZA);
    }
  };  

  const onView = async (index) => {
    setPatientDetails(patientData[index]);
    navigate(doctorRoutes.reportviewsummary, {
      state: {
        data: patientData[index],
      },
    });
  };

  const onRefer = async (index) => {
    setIsRefer(true);
    setPatientDetails(patientData[index]);

    const arr = [];

    for (const data of specialityMasterData) {
      const obj = {
        label: data?.speciality,
        value: data?.id,
      };
      arr.push(obj);
    }
    setSpecialityData(arr);
  };

  const onAppointmnet = (index) => {
    setIsBookAppoinment(true);
    setPatientDetails(patientData[index]);
  };

  const handleBookAppointmentModalClose = () => {
    clearDoctorSlots();
    setIsBookAppoinment(false);
  };

  return (
    <Box
      sx={{
        ...patientViewCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ position: 'relative' }}>
        {!isMobilePort && patientState?.patientData?.length > 0 && (
          <Box         
            sx={patientViewCard_style.filterTotalSx}
          >
            <Dropdown
              selectOption={filtetOption}
              onChange={(e) => FilterPatientData(e?.target?.value)}
              value={filter}
              rootStyle={patientViewCard_style.filterSx}
            />
          </Box>
        )}
        {loading &&
          Array(5)
            .fill(0)
            .map((i) => <DoctorModuleSkeleton key={i} />)}
        {patientState?.patientData?.length === 0 && (
          <Box sx={loading ? { display: 'none' } : ''}>
            <ClinicalEmptyState
              IconRequired={<EmptyPatients />}
              emptyStateStyle={{ mt: 8 }}
              subHead="No patients to display.
                  Add patients."
              subHeadStyle={patientViewCard_style.subHeadSx}
            />
          </Box>
        )}
        <Box sx={loading ? { display: 'none' } : ''}>
          {patientData &&
            patientData.map((val, index) => {
              return (
                <Box key={index}>
                  <PatientCard
                    PatientName={val?.patient_profile?.name}
                    mobile={val?.patient_profile?.mobile_no}
                    subText={val?.patient_profile?.gender}
                    age={val?.patient_profile?.age}
                    countyCode={val?.patient_profile?.country_code}
                    // Route to Report View Summary Page
                    onView={() => onView(index)}
                    onRefer={() => onRefer(index)}
                    onAppointmnet={() => onAppointmnet(index)}
                    index={index}
                  />
                </Box>
              );
            })}
        </Box>

        {isMobilePort && (
          <Box
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              bottom: '77px',
              right: '17px',
              cursor: 'pointer',
            }}
          >
            <FloatingAddIcon onClick={onAddPatient} />
          </Box>
        )}
      </Box>

      <BookappoinmentDialog
        isModalOpen={isBookAppoinment}
        handleClose={() => handleBookAppointmentModalClose()}
        patientData={patientDetails}
        setTabIndex={setTabIndex}
        isMobileResponsive={isMobilePort}
      />

      <ReferDialog
        isMobileResponsive={isMobilePort}
        isModalOpen={isRefer}
        handleClose={() => setIsRefer(false)}
        specialization={specialityData}
        patientData={patientDetails}
      />
    </Box>
  );
}

PatientViewCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onAddPatient: PropTypes.func,
  setTabIndex: PropTypes.func,
};

export { PatientViewCard };
