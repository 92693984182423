/* eslint-disable react/jsx-no-useless-fragment */
import { doctorRoutes } from '@hc/routes';
import {
  useDoctorBySpeciality,
  useDoctorSpeciality,
  useSummary,
  useViewPort,
} from '@hc/store';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  AddSummaryIcon,
  Button,
  CustomTextArea,
  DialogModal,
  Dropdown,
  Input,
  MobileInput,
} from '../../../atoms';
import { PastReferNote } from '../pastReferNote';
import { SummaryReferNote } from '../summaryReferNote';
import { summaryRefer_style } from './style';

function SummaryRefer(props) {
  const {
    summaryView = false,
    summaryModelView = false,
    addTitle = '',
    summaryTitle = '',
    className = '',
    rootStyle = {},
    ...rest
  } = props;
  const navigate = useNavigate();

  // general Hooks
  const [inputShown, setInputShown] = useState(false);

  const {
    summaryState,
    updateReferToData,
    updateSummaryState,
    sentReferralDoctor,
    initialReferralModelData,
  } = useSummary(
    (state) => ({
      summaryState: state.summaryState,
      updateReferToData: state.updateReferToData,
      updateSummaryState: state.updateSummaryState,
      sentReferralDoctor: state.sentReferralDoctor,
      initialReferralModelData: state.initialReferralModelData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { specialityData, getSpecialityData } = useDoctorSpeciality(
    (state) => ({
      specialityData: state.specialityData,
      getSpecialityData: state.getSpecialityData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { doctorBySpecialityData, getDoctorBySpecialityData, clearState } =
    useDoctorBySpeciality(
      (state) => ({
        clearState: state.clearState,
        doctorBySpecialityData: state.doctorBySpecialityData,
        getDoctorBySpecialityData: state.getDoctorBySpecialityData,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const {
    referToDoctorModelDetails,
    pastSummaryData,
    isNewDoctor,
    referToDoctorDetails,
  } = summaryState;
  const { doctorSpeciality, doctorId, notes, doctorMobileNumber, doctorName } =
    referToDoctorModelDetails;

  const { referralNoteData } = pastSummaryData;

  // Input open Common Func
  const commonOpenFun = () => {
    setInputShown(!inputShown);
  };
  // Input close icon
  const handleClose = () => {
    setInputShown(false);
    initialReferralModelData();
  };

  const sendReferral = async () => {
    if (doctorSpeciality) {
      if (isNewDoctor) {
        if (doctorMobileNumber && doctorName) {
          // API CALL

          const referralResponse = await sentReferralDoctor();
          if (referralResponse?.code === 200) {
            setInputShown(false);
            initialReferralModelData();
            navigate(doctorRoutes?.referralsuccess);
            clearState();
          }
        } else {
          toast.error('Please enter doctor name & mobile number!');
        }
      } else if (doctorId) {
        // API CALL
        const referralResponse = await sentReferralDoctor();
        if (referralResponse?.code === 200) {
          setInputShown(false);
          initialReferralModelData();
          navigate(doctorRoutes?.referralsuccess);
          clearState();
        }
      } else {
        toast.error('Please select doctor!');
      }
    } else {
      toast.error('Please select speciality!');
    }
  };
  const onChange = async (key, value) => {
    if (key === 'doctorSpeciality') {
      updateReferToData(key, value);
      updateSummaryState('isNewDoctor', false);
      updateReferToData('doctorId', '');
      updateReferToData('notes', '');
      updateReferToData('doctorMobileNumber', '');
      updateReferToData('doctorName', '');
      await getDoctorBySpecialityData(value);
    } else if (key === 'doctorId' && value === 'addDoctor') {
      updateSummaryState('isNewDoctor', true);
      updateReferToData('doctorId', '');
      updateReferToData('notes', '');
    } else {
      updateReferToData(key, value);
    }
  };

  const initialData = async () => {
    await getSpecialityData();
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      sx={{
        ...summaryRefer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Summary Title with Import Rx Group Text */}

      <Typography sx={summaryRefer_style.addSummaryTitleSx}>
        {summaryTitle}
      </Typography>

      {!summaryModelView && !summaryView && (
        <>
          {/* {!referDoctorAdded ? ( */}
          <>
            {!inputShown && !referToDoctorDetails?.doctorId && (
              <Box sx={summaryRefer_style.inputTextShowSx}>
                <IconButton disableRipple onClick={() => commonOpenFun()}>
                  <AddSummaryIcon />
                </IconButton>
                <Typography sx={summaryRefer_style.summaryTitlesx}>
                  {addTitle}
                </Typography>
              </Box>
            )}

            {inputShown && (
              <Box>
                <DialogModal
                  maxModalWidth="xl"
                  topDivider
                  bottomDivider
                  isBackRequired={isMobilePort ? true : false}
                  isCloseOut={isMobilePort ? false : true}
                  isMobileResponsive={isMobilePort}
                  title="Refer to another doctor"
                  dialogRootStyle={{
                    width: '650px',
                  }}
                  titleStyle={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: 'text.primary',
                  }}
                  content={
                    <Grid container rowSpacing={3}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography gutterBottom fontWeight={600} mb={0.5}>
                              Doctor Specialization
                            </Typography>
                            <Dropdown
                              selectOption={specialityData ?? []}
                              value={doctorSpeciality}
                              onChange={(event) =>
                                onChange('doctorSpeciality', event.target.value)
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {!isNewDoctor ? (
                        <Grid item xs={12}>
                          <Grid container columnSpacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                gutterBottom
                                fontWeight={600}
                                mb={0.5}
                              >
                                Select Doctor
                              </Typography>
                              {/* {doctorBySpecialityData.length > 0 ? ( */}
                              <Dropdown
                                disabled={doctorBySpecialityData.length === 0}
                                selectOption={doctorBySpecialityData ?? []}
                                value={doctorId}
                                onChange={(event) =>
                                  onChange('doctorId', event.target.value)
                                }
                                addDoctor
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  gutterBottom
                                  fontWeight={600}
                                  mb={0.5}
                                >
                                  Doctor&apos;s Phone Number
                                </Typography>
                                <MobileInput
                                  type="number"
                                  value={doctorMobileNumber ?? ''}
                                  id="doctorMobileNumber"
                                  // onChange={}
                                  onChange={(e) => {
                                    onChange('doctorMobileNumber', e?.mobile);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography
                                  gutterBottom
                                  fontWeight={600}
                                  mb={0.5}
                                >
                                  Doctor&apos;s Name
                                </Typography>
                                <Input
                                  value={doctorName ?? ''}
                                  id="doctorName"
                                  onChange={(e) => {
                                    onChange('doctorName', e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography gutterBottom fontWeight={600} mb={0.5}>
                              Add Notes
                            </Typography>
                            <CustomTextArea
                              fullWidth
                              rows={3}
                              placeholder="Add some notes to the referred doctor"
                              rowsMax={5}
                              sx={{
                                width: '100%',
                              }}
                              onChange={(e) => {
                                onChange('notes', e.target.value);
                              }}
                              value={notes ?? ''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  actions={
                    <Grid
                      p={1}
                      container
                      columnSpacing={1.5}
                      justifyContent={isMobilePort ? 'space-around' : 'end'}
                    >
                      {isMobilePort && (
                        <Grid item xs={6}>
                          <Button onClick={handleClose} variant="outlined">
                            Cancel
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={isMobilePort ? 6 : 2.5}>
                        <Button onClick={() => sendReferral()}>
                          {' '}
                          Send Referral
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  isDialogOpened={inputShown}
                  handleCloseDialog={handleClose}
                />
              </Box>
            )}
            {!inputShown && referToDoctorDetails?.doctorId?.length > 0 && (
              <Box>
                <SummaryReferNote />
              </Box>
            )}
          </>
        </>
      )}
      {summaryView && (
        <>
          {Object.keys(referralNoteData)?.length > 0 &&
          referralNoteData?.doctorName?.length > 0 ? (
            <Box p={2}>
              <Box>
                <PastReferNote ShowNotes={false} />
              </Box>
            </Box>
          ) : (
            <Box sx={summaryRefer_style.boxCardSx}>
              <Typography sx={summaryRefer_style.valTextSx}>
                No referral found!
              </Typography>
            </Box>
          )}
        </>
      )}

      {summaryModelView && (
        <>
          {Object.keys(referToDoctorDetails)?.length > 0 &&
          referToDoctorDetails?.doctorName?.length > 0 ? (
            <Box sx={summaryRefer_style.boxCardSx}>
              <PastReferNote
                ShowNotes={false}
                summaryModelView={summaryModelView}
              />
            </Box>
          ) : (
            <Box sx={summaryRefer_style.boxCardSx}>
              <Typography sx={summaryRefer_style.valTextSx}>
                No referral found!
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

SummaryRefer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  summaryTitle: PropTypes.string,
  viewSummaryTitle: PropTypes.string,
  pastreferShown: PropTypes.bool,
  addTitle: PropTypes.string,
  summaryView: PropTypes.bool,
  summaryModelView: PropTypes.bool,
};

export { SummaryRefer };
