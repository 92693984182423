export const homepage_style = {
  tabStyleSx: {
    backgroundColor: 'transparent',
    width: ' 100%',
    margin: '0px auto',
    display: 'flex',
    mr: 2,
    borderBottom: '2px solid #EBFAF7',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
  },
  underTabStyleSx: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#007965',
      textAlign: 'left',
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: '155px 155px 155px 120px',
      boxSizing: 'border-box',
      mx: 0.5,
      padding: 1,
      gridColumnGap: '32px',
      textAlign: 'left',
    },
  },
  numberCountSx: {
    width: '24px',
    height: '24px',
    fontSize: '14px',
  },
  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    textAlign: 'left',
    color: '#808080',
    fontWeight: 500,
    '&.Mui-selected': {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  subHeadSx: {
    fontSize: '16px',
    color: '#888888',
    // p: 5.5,
    fontWeight: '25px',
    textAlign: 'center',
  },
};
