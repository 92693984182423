export const prescriptionButton_style = {
  rootSx: { display: 'flex' },
  buttonSx: {
    px: 1,
    my: 2,
    mr: 0.5,
    bgcolor: 'white',
    color: '#000',
    borderRadius: '8px',
    '&:hover': {
      color: '#000',
    },
    border: '1px solid #E3E3E3',
    minWidth: 'fit-content',
  },
};
