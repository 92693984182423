/* eslint-disable no-sequences */
/* eslint-disable react/no-children-prop */

import { Layout } from '@components/doctor';
import { getAppointmentTime, getDateFormat } from '@hc/dayjs';
import { doctorRoutes } from '@hc/routes';
import {
  usePatientDetails,
  useReferrals,
  useSummary,
  useViewPort,
} from '@hc/store';
import { useDoctorAppointment } from '@hc/store/doctor/appointments';
import { DataTabs, FilterSortIcon, Input, SearchIcon } from '@hc/ui/atoms';
import {
  AddPatientUi,
  DoctorAppointmentCalender,
  LogoutModal,
  ProfileMobilePage,
} from '@hc/ui/components';
import { AppointmentUi } from '@hc/ui/components/doctor/appointmentui';
import { DoctorVerification } from '@hc/ui/components/doctor/doctorVerification';
import { DoctorBanner } from '@hc/ui/components/doctor/doctorbanner';
import { PatientViewCard } from '@hc/ui/components/doctor/patientViewCard';
import { Refferals } from '@hc/ui/components/doctor/refferals/refferalMain';
import { getAuthData, localStorageKeys } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';
import { homepage_style } from './style';

export default function Home() {
  const [tabIndex, setTabIndex] = useState('1');
  const [currentTime, setCurrentTime] = useState('');
  const [enableLoading, setEnableLoading] = useState();
  const [resumeConsult, setResumeConsult] = useState(false);
  const navigate = useNavigate();

  console.log(tabIndex,"tabIndex")

  const location = useLocation();
  const data = getAuthData();
  const [onsearchValue, setOnsearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [filter, setFilterval] = useState(false);

  const date = new Date().toLocaleString('default', { month: 'long' });
  const result = `${date.substring(0, 3)} ${new Date().getFullYear()}`;

  const onAddPatient = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Store Data
  const {
    doctorAppointmentData,
    getDoctorAppointmentData,
    getDashBoardData,
    setFilter,
    updateAppointmentStore,
    setSeachFilter,
    dashBoardLoading,
  } = useDoctorAppointment(
    (state) => ({
      setSeachFilter: state.setSeachFilter,
      updateAppointmentStore: state.updateAppointmentStore,
      dashBoardLoading: state.dashBoardLoading,
      getDashBoardData: state.getDashBoardData,
      doctorAppointmentData: state.doctorAppointmentData,
      getDoctorAppointmentData: state.getDoctorAppointmentData,
      setFilter: state.setFilter,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { patientDetails } = doctorAppointmentData;

  // store Data
  const {
    patientState,
    setPatientSeachFilter,
    getPatientData,
    updatePatientState,
    getSpeacialityMasterData,
  } = usePatientDetails(
    (state) => ({
      updatePatientState: state.updatePatientState,
      setPatientSeachFilter: state.setPatientSeachFilter,
      getSpeacialityMasterData: state.getSpeacialityMasterData,
      getPatientData: state.getPatientData,
      patientState: state.patientState,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { patientData } = patientState;

  const { getAppointmentDataById, clearSummaryState } = useSummary(
    (state) => ({
      clearSummaryState: state.clearSummaryState,
      getAppointmentDataById: state.getAppointmentDataById,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { referralsData, setSeachReferralsFilter, getReferralsData } =
    useReferrals(
      (state) => ({
        setSeachReferralsFilter: state.setSeachReferralsFilter,
        referralsData: state.referralsData,
        getReferralsData: state.getReferralsData,
      }),
      (prev, curr) => isEqual(prev, curr)
    );

  const { isMobilePort, homeTabIndex } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
    homeTabIndex: state.homeTabIndex,
  }));

  const onSearch = (val) => {
    setOnsearchValue(val?.target?.value);
    if (homeTabIndex === '0') {
      setSeachFilter(val?.target?.value);
    } else if (homeTabIndex === '1') {
      setSeachReferralsFilter(val?.target?.value);
    } else if (homeTabIndex === '2') {
      setPatientSeachFilter(val?.target?.value);
    }
  };

  const setProfile = () => {
    navigate(doctorRoutes?.profile);
  };

  const setAvailability = () => {
    navigate(doctorRoutes.availability);
  };

  const rxGroup = () => {
    navigate(doctorRoutes.rxgroup);
  };
  const [openModel, setModel] = useState(false);
  const LogoutFun = () => {
    setModel(true);
  };
  const handleLogout = () => {
    toast.success('Signed Out Successfully');
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleCancleModel = () => {
    setModel(false);
  };

  const verifyDoctorMenuItems = [
    {
      name: 'View Profile',
      onclick: setProfile,
    },
    {
      name: 'Logout',
      onclick: LogoutFun,
    },
  ];

  const menuItems = [
    {
      name: 'View Profile',
      onclick: setProfile,
    },
    {
      name: 'Set Availability',
      onclick: setAvailability,
    },
    {
      name: 'Rx Group',
      onclick: rxGroup,
    },
    {
      name: 'Logout',
      onclick: LogoutFun,
    },
  ];

  const tabs = [
    {
      value: '1',
      label: 'Appointments',
      count: (
        <Avatar
          alt=""
          sx={{
            backgroundColor: tabIndex === '1' ? '#007965' : 'text.primary',
            color: '#fff',
            ...homepage_style.numberCountSx,
          }}
        >
          {`${
            patientDetails?.length > 0 && patientDetails?.length < 10
              ? `0${patientDetails?.length}`
              : patientDetails?.length
          }`}
        </Avatar>
      ),
    },
    {
      value: '2',
      label: 'Referrals',
      count: (
        <Avatar
          alt=""
          sx={{
            backgroundColor: tabIndex === '2' ? '#007965' : 'text.primary',
            color: '#fff',
            ...homepage_style.numberCountSx,
          }}
        >
          {`${
            referralsData?.length > 0 && referralsData?.length < 10
              ? `0${referralsData?.length}`
              : referralsData?.length
          }`}
        </Avatar>
      ),
    },
    {
      value: '3',
      label: 'Patients',
      count: (
        <Avatar
          alt=""
          sx={{
            backgroundColor: tabIndex === '3' ? '#007965' : 'text.primary',
            color: '#fff',
            ...homepage_style.numberCountSx,
          }}
        >
          {`${
            patientData?.length > 0 && patientData?.length < 10
              ? `0${patientData?.length}`
              : patientData?.length
          }`}
        </Avatar>
      ),
    },
  ];

  const onAddSummaryClick = async (val, index) => {
    if (val?.appointment_date === getDateFormat(new Date(), 'YYYY-MM-DD')) {
      if (
        getAppointmentTime(val?.appointment_time_slot, 'HH:mm:ss') <=
        currentTime
      ) {
        setEnableLoading(index);
        const data = localStorage.getItem('summaryState');
        const jsonData = JSON.parse(data);
        localStorage.setItem('index', jsonData?.appointmentData?.id);
        if (jsonData?.appointmentData?.id) {
          if (jsonData?.appointmentData?.id !== val?.appointment_id) {
            clearSummaryState();
            localStorage.setItem('startCall', 0);
            const appointmentData = await getAppointmentDataById(
              val?.appointment_id
            );
            if (appointmentData === '200') {
              setResumeConsult(true);
              navigate(doctorRoutes?.summary, {
                state: {
                  timeSlot: getAppointmentTime(
                    val?.appointment_time_slot,
                    'HH:mm:ss'
                  ),
                  meetUrl: val?.meet_url,
                },
              });
            }
          } else {
            navigate(doctorRoutes?.summary, {
              state: {
                timeSlot: getAppointmentTime(
                  val?.appointment_time_slot,
                  'HH:mm:ss'
                ),
                meetUrl: val?.meet_url,
              },
            });
          }
        } else {
          clearSummaryState();
          const appointmentData = await getAppointmentDataById(
            val?.appointment_id
          );
          if (appointmentData === '200') {
            navigate(doctorRoutes?.summary);
          }
        }
      } else {
        toast.error('You can do action only 5 mins before appointment time');
      }
    } else {
      toast.error('Action cannot be done for future appointment');
    }
  };

  const getInitialData = async () => {
    await getDoctorAppointmentData();
    await getDashBoardData();
    await getPatientData();
    await getSpeacialityMasterData();
    await getReferralsData();
  };

  const getAppointments = async () => {
    setTabIndex('1');
    await getDoctorAppointmentData();
  };

  const getReferrals = async () => {
    setTabIndex('2');
    await getReferralsData();
  };

  const onDateSelect = async (index, val) => {
    setSelectedIndex(index);
    updateAppointmentStore('appointmentCurrentDate', val?.value);
    await getDoctorAppointmentData();
    const month = new Date(val?.value).toLocaleString('default', {
      month: 'long',
    });

    const date = `${month.substring(0, 3)} ${new Date(
      val?.value
    ).getFullYear()}`;

    setSelectedDate(date);
  };

  const todayClick = async () => {
    updateAppointmentStore('appointmentCurrentDate', new Date());
    await getDoctorAppointmentData();
    setSelectedIndex(undefined);
    setSelectedDate(result);
  };

  const onTabChnage = (tab) => {
    setTabIndex(tab);
    sessionStorage.setItem(localStorageKeys.doctorPage, tab);
  };

  const onFilterClick = () => {
    setFilterval(!filter);
    if (homeTabIndex === '0') {
      setFilter(!filter);
    } else if (homeTabIndex === '2') {
      if (filter === true) {
        const AZ = patientData?.sort((a, b) =>
          a.patient_profile.name.localeCompare(b.name)
        );
        updatePatientState('patientData', AZ);
      } else if (filter === false) {
        const ZA = patientData?.sort((a, b) =>
          b.patient_profile.name.localeCompare(a.name)
        );
        updatePatientState('patientData', ZA);
      }
    }
  };

  useEffect(() => {
    setTabIndex(sessionStorage.getItem(localStorageKeys.doctorPage) || '1');
    data?.is_verified && getInitialData();
    const myIntervalll = setInterval(() => {
      const date = new Date();
      const totalMilliSeconds = date.getTime();
      const minutes = 5;
      const millisecondsToSubtract = minutes * 60 * 1000;
      const newDate = new Date(totalMilliSeconds + millisecondsToSubtract);
      const dateTime = getAppointmentTime(newDate, 'HH:mm:ss');
      setCurrentTime(dateTime);
    }, 1000);

    return () => {
      clearInterval(myIntervalll);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.fromNotification === 'referral') {
      getReferrals();
    } else if (location?.state?.fromNotification === 'appointments') {
      getAppointments();
    }
    localStorage.setItem(localStorageKeys.doctorVersion, version);
  }, [location]);

  // doctorPage
  return (
    <Box sx={homepage_style.rootSx}>
      <Layout
        isDoctorVerfied={data?.is_verified}
        children={
          <Box height={isMobilePort && '100vh'} mb={isMobilePort && 12}>
            {!isMobilePort ? (
              <Box sx={{ minHeight: '95vh', mt: 8.5 }}>
                {data?.is_verified ? (
                  <Box>
                    <DoctorBanner
                      onAddPatient={onAddPatient}
                      doctorName={doctorAppointmentData?.doctorName}
                      apponitmentCount={doctorAppointmentData?.newAppointments}
                      followUPCount={doctorAppointmentData?.followUps}
                      refferals={doctorAppointmentData?.referralToday}
                      loading={dashBoardLoading}
                    />
                    <Box
                      sx={{
                        mt: 2.5,
                        backgroundColor: '#FFFFFF',
                        borderRadius: '12px',
                        height: '81vh',
                        overflow: 'scroll',
                        boxShadow: '0px 1px 10px #0000000A',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'sticky',
                          top: 0,
                          zIndex: 100,
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        <DataTabs
                          tabIndex={tabIndex}
                          setTabIndex={(param) => onTabChnage(param)}
                          totalStyle={homepage_style.tabStyleSx}
                          underTabSx={homepage_style.underTabStyleSx}
                          tabStyle={homepage_style.tabSx}
                          tabs={tabs}
                          count
                          searchbar
                        />
                      </Box>

                      <Box sx={{ px: 2 }}>
                        {tabIndex === '1' && (
                          <AppointmentUi
                            enableLoading={enableLoading}
                            resumeConsult={resumeConsult}
                            setEnableLoading={setEnableLoading}
                            onClick={onAddSummaryClick}
                          />
                        )}
                        {tabIndex === '2' && (
                          <Refferals isMobileResponsive={isMobilePort} />
                        )}
                        {tabIndex === '3' && (
                          <PatientViewCard
                            setTabIndex={setTabIndex}
                            onAddPatient={onAddPatient}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <DoctorVerification doctorName={data?.name} />
                )}
              </Box>
            ) : (
              <Box>
                {data?.is_verified ? (
                  <Box
                    sx={{
                      px: 2,
                      mt:
                        homeTabIndex === '0'
                          ? 32.5
                          : homeTabIndex === '1' || homeTabIndex === '2'
                          ? 17
                          : 9.5,
                      pb: 8,
                      mb: 12,
                    }}
                  >
                    {homeTabIndex !== '3' && (
                      <Box sx={homepage_style.bannerRootSx}>
                        {homeTabIndex === '0' && (
                          <Box pb={1.5}>
                            <DoctorAppointmentCalender
                              selectedDate={
                                selectedDate ? selectedDate : result
                              }
                              value={selectedIndex}
                              setSelectedIndex={setSelectedIndex}
                              onDateSelect={onDateSelect}
                              todayClick={todayClick}
                            />
                          </Box>
                        )}

                        <Grid container columnSpacing={1.5}>
                          <Grid
                            item
                            xs={homeTabIndex !== '1' ? 10.5 : 12}
                            sm={homeTabIndex !== '1' ? 10.5 : 12}
                          >
                            <Input
                              placeholder="Search"
                              textFieldStyle={homepage_style.searchFiledSx}
                              onChange={onSearch}
                              value={onsearchValue}
                              startAdornment={!onsearchValue && <SearchIcon />}
                            />
                          </Grid>
                          {homeTabIndex !== '3' && homeTabIndex !== '1' && (
                            <Grid item sm={1.5} xs={1.5}>
                              <Box sx={homepage_style.filterBoxSx}>
                                <Box
                                  alignSelf="center"
                                  onClick={() => onFilterClick()}
                                >
                                  <FilterSortIcon
                                    rootStyle={{
                                      color: filter ? '#007965' : '#000',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    )}

                    {homeTabIndex === '0' && (
                      <AppointmentUi
                        enableLoading={enableLoading}
                        resumeConsult={resumeConsult}
                        setEnableLoading={setEnableLoading}
                        onClick={onAddSummaryClick}
                      />
                    )}
                    {homeTabIndex === '1' && (
                      <Refferals isMobileResponsive={isMobilePort} />
                    )}
                    {homeTabIndex === '2' && (
                      <PatientViewCard
                        setTabIndex={setTabIndex}
                        onAddPatient={onAddPatient}
                      />
                    )}
                    {homeTabIndex === '3' && <ProfileMobilePage />}
                  </Box>
                ) : (
                  <Box px={2} mt={20}>
                    <DoctorVerification isMobilePort doctorName={data?.name} />
                  </Box>
                )}
              </Box>
            )}
            <AddPatientUi
              isModalOpen={isModalOpen}
              handleClose={handleClose}
              isMobileResponsive={isMobilePort}
            />
            {openModel && (
              <LogoutModal
                isModalOpen={openModel}
                handleClose={handleCancleModel}
                handleDelete={handleLogout}
              />
            )}
          </Box>
        }
        menuItems={data?.is_verified ? menuItems : verifyDoctorMenuItems}
        is
      />
    </Box>
  );
}
