/* eslint-disable no-underscore-dangle */
import { TrashIcon } from '@atoms';
import { useAvailability, useViewPort } from '@hc/store';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { availableTimeSelectionInput_style } from './style';

function AvailableTimeSelectionInput(props) {
  const {
    className = '',
    rootStyle = {},
    data = {},
    isOverRide,
    istimeValid,
    isValid,
    getFromTime = () => {},
    getToTime = () => {},
    parentIndex = 0,
    childIndex,
    day = 'available',
    ...rest
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { deleteAvailability } = useAvailability(
    (state) => ({
      deleteAvailability: state.deleteAvailability,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const onDeleteSlot = () => {
    if (isOverRide) {
      deleteAvailability(false, true, childIndex);
    } else {
      deleteAvailability(false, false, parentIndex, childIndex);
    }
  };

  return (
    <Box
      sx={{
        ...availableTimeSelectionInput_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container>
        <Grid
          item
          xs={7}
          sm={9}
          md={9}
          lg={9}
          sx={{ display: 'flex' }}
          gap={isMobilePort ? 1.5 : 2}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                {...props}
                slotProps={{
                  textField: {
                    required: true,
                    id: `${day}${childIndex}0`,
                  },
                }}
                ampm
                inputFormat="HH:MM"
                onChange={(event) =>
                  getFromTime(
                    moment(event._d).format('HH:mm'),
                    childIndex,
                    parentIndex,
                  )
                }
                defaultValue={moment(data?.fromTime, [
                  moment.ISO_8601,
                  'HH:mm',
                ])}
                sx={{
                  '&.MuiFormControl-root': {
                    width: isMobilePort ? '100px' : '140px',
                  },

                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                      width: isMobilePort ? '100px' : '140px',
                    },
                    '&:hover fieldset': {
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                    },
                    '&.Mui-focused fieldset': {
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                    },
                  },
                  '& .MuiFormHelperText-root': {
                    mx: 0,
                  },
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    defaultValue={data?.toTime}
                    InputProps={{
                      sx: { fontSize: '16px', fontWeight: '600' },
                      endAdornment: null,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box display="flex" alignSelf="center" justifyContent="start">
            <Typography fontWeight={600}>-</Typography>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                slotProps={{
                  textField: {
                    required: true,
                    id: `${day}${childIndex}1`,
                  },
                }}
                ampm
                inputFormat="HH:MM"
                onChange={(event) =>
                  getToTime(
                    moment(event._d).format('HH:mm'),
                    childIndex,
                    parentIndex,
                  )
                }
                defaultValue={moment(data?.toTime, [moment.ISO_8601, 'HH:mm'])}
                sx={{
                  '&.MuiFormControl-root': {
                    width: isMobilePort ? '100px' : '140px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '8px',
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                      width: isMobilePort ? '100px' : '140px',
                    },
                    '&:hover fieldset': {
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                    },
                    '&.Mui-focused fieldset': {
                      border:
                        !istimeValid && isValid === childIndex
                          ? '1.5px solid #ff4040'
                          : '1.5px solid #DBEAE8',
                    },
                  },
                  '& .MuiFormHelperText-root': {
                    mx: 0,
                  },
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    defaultValue={data?.toTime}
                    InputProps={{
                      sx: { fontSize: '16px', fontWeight: '600' },
                      endAdornment: null,
                    }}
                  />
                )}
                {...props}
              />
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid
          item
          xs={5}
          sm={3}
          md={3}
          lg={3}
          pt={1.5}
          pl={isMobilePort ? 10 : 6}
          pr={isMobilePort ? 0 : null}
          alignSelf="center"
          justifyContent="end"
        >
          {childIndex > 0 ? (
            <TrashIcon
              id={`delete${parentIndex}`}
              rootStyle={{
                width: '18px',
                height: '20px',
                cursor: 'pointer',
              }}
              onClick={() => onDeleteSlot(parentIndex, childIndex)}
            />
          ) : (
            <Box width={1} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

AvailableTimeSelectionInput.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isOverRide: PropTypes.bool,
  getFromTime: PropTypes.func,
  getToTime: PropTypes.func,
  data: PropTypes.object,
  istimeValid: PropTypes.bool,
  isValid: PropTypes.bool,
  masterData: PropTypes.array,
  parentIndex: PropTypes.number,
  childIndex: PropTypes.number,
  day: PropTypes.any,
};

export { AvailableTimeSelectionInput };
