/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
import { Button } from '@atoms';
import { getAppointmentTime } from '@hc/dayjs';
import { doctorRoutes } from '@hc/routes';
import {
  useAppointment,
  useDoctorAppointment,
  useSummary,
  useViewPort,
} from '@hc/store';
import { NoAppointments } from '@hc/ui/atoms';
import {
  AppointmentListCard,
  AppointmentMobileCard,
  CancelAppointmentDialog,
  ClinicalEmptyState,
  DoctorModuleSkeleton,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Menu, MenuItem, Select, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { BiChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { CancelIcon, TickIcon } from '../../../atoms';
import { DateChanger } from '../datechanger';
import { appointmentUi_style } from './style';

export function AppointmentUi(props) {
  const {
    className = '',
    rootStyle = {},
    onsearchValue,
    enableLoading,
    setEnableLoading,
    resumeConsult,
    onClick = () => false,
    ...rest
  } = props;

  const navigate = useNavigate();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const keydata = parseJwt(authToken);

  const { appointmentCancel, appointmentCancelLoading } = useAppointment(
    (state) => ({
      appointmentCancel: state.cancelAppointment,
      appointmentCancelLoading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    updateAppointmentStore,
    getDoctorAppointmentData,
    doctorAppointmentData,
    getDashBoardData,
    setFilter,
    loading,
  } = useDoctorAppointment(
    (state) => ({
      setFilter: state.setFilter,
      loading: state.loading,
      getDashBoardData: state.getDashBoardData,
      updateAppointmentStore: state.updateAppointmentStore,
      getDoctorAppointmentData: state.getDoctorAppointmentData,
      doctorAppointmentData: state.doctorAppointmentData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    getPastSummaryData,
    getAppointmentDataById,
    clearSummaryState,
    getAppointmentLoading,
  } = useSummary(
    (state) => ({
      clearSummaryState: state.clearSummaryState,
      getPastSummaryData: state.getPastSummaryData,
      getAppointmentDataById: state.getAppointmentDataById,
      getAppointmentLoading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );
  const { patientDetails, appointmentCurrentDate } = doctorAppointmentData;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const filter = [
    {
      value: 'DESC',
      label: 'Newest First',
    },
    {
      value: 'ASC',
      label: 'Oldest First',
    },
  ];
  const [cancelAppointmentData, setCancelAppointmentData] = useState({});
  const [open, setOpen] = React.useState(filter[0]);
  const [cancelAppointment, setCancelAppointment] = useState(null);
  const [currentTime, setCurrentTime] = useState('');
  const [lastFiveMin, setLastFiveMin] = useState('');
  const [timeLapsed, setTimeLapsed] = useState('');
  const [timerStartTime, setTimerStartTime] = useState('');
  const [modalOpen, SetmodalOpen] = useState(false);

  const handleModalClose = () => {
    SetmodalOpen(false);
    setCancelAppointment(null);
  };

  const handleClick = (event, item) => {
    if (cancelAppointment !== event.currentTarget) {
      setCancelAppointmentData(item);
      setCancelAppointment(event.currentTarget);
    }
  };

  const viewSummaryClick = async (item, index) => {
    clearSummaryState();
    setEnableLoading(index);
    const appointmentData = await getAppointmentDataById(item?.appointment_id);
    if (appointmentData === '200') {
      localStorage.removeItem('summaryState');
      await getPastSummaryData(item?.appointment_id);
      navigate(doctorRoutes.summary, {
        state: {
          isViewSummary: true,
          apptData: item,
        },
      });
    }
  };

  const onDayChange = (val, num, day) => {
    if (val === 'add') {
      day.setDate(day.getDate() + num);
    } else {
      day.setDate(day.getDate() - num);
    }
    getDoctorAppointmentData();
    getDashBoardData();
  };

  const handleCancelClose = () => {
    setCancelAppointment(null);
  };

  const handleChange = async (value) => {
    setOpen(value);
    await setFilter(value?.value);
  };

  const cancelAppoinmentClick = () => {
    SetmodalOpen(true);
  };

  const handleCancelAppointment = async () => {
    const payload = {
      appointed_doctor_id: cancelAppointmentData?.assigned_doctor ?? '',
      appointment_id: cancelAppointmentData?.appointment_id ?? '',
      cancellationReason: cancelAppointmentData?.cancellationReason ?? '',
      event_id: cancelAppointmentData?.event_id ?? null,
      canceledBy: keydata?.id ?? '',
    };
    setCancelAppointment(null);
    SetmodalOpen(false);
    // CANCEL APPOINTMENT SERVICE CALL
    const response = await appointmentCancel(payload);
    if (response?.data?.status?.code === 200) {
      getDoctorAppointmentData(true);
      getDashBoardData(true);
      toast.success(
        response?.data?.status?.message ?? `Appointment cancelled successfully.`
      );
    } else {
      return toast.error(
        response?.data?.status?.message ??
          response?.data?.data?.message ??
          'Something went wrong please try again!'
      );
    }
  };

  const onClickToday = () => {
    updateAppointmentStore('appointmentCurrentDate', new Date());
    getDoctorAppointmentData();
    getDashBoardData('getInitial');
  };

  useEffect(() => {
    const myIntervalll = setInterval(() => {
      const date = new Date();
      const totalMilliSeconds = date.getTime();
      const minutes = 5;
      const millisecondsToSubtract = minutes * 60 * 1000;
      const newDate = new Date(totalMilliSeconds + millisecondsToSubtract);
      const dateTime = getAppointmentTime(newDate, 'HH:mm:ss');
      const liveTime = getAppointmentTime(date, 'HH:mm:ss');
      setTimerStartTime(date);
      setCurrentTime(dateTime);
      setLastFiveMin(liveTime);
      const timeLapsedTime = 20;
      const timeLapsedMillisecondsToSubtract = timeLapsedTime * 60 * 1000;
      const timeLapsedTimenewDate = new Date(
        totalMilliSeconds - timeLapsedMillisecondsToSubtract
      );
      const timeLapse = getAppointmentTime(timeLapsedTimenewDate, 'HH:mm:ss');
      setTimeLapsed(timeLapse);
    }, 1000);

    return () => {
      clearInterval(myIntervalll);
    };
  }, []);

  return (
    <Box
      sx={{
        ...appointmentUi_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobilePort && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack direction="row" spacing={1} mt={1.2} alignItems="center">
            <Button
              variant="outlined"
              onClick={() => {
                onClickToday();
              }}
              sx={appointmentUi_style.todaybuttonSx}
            >
              <Typography
                component="span"
                color="text.primary"
                fontSize="14px"
                fontWeight="500"
              >
                Today
              </Typography>
            </Button>

            <DateChanger
              rightIcon
              leftIcon
              startDate={appointmentCurrentDate}
              readOnly
              onDayChange={onDayChange}
              iconStyle={{ color: 'common.black' }}
            />
          </Stack>

          <Box>
            {patientDetails?.length > 0 && (
              <Select
                value={`${open?.value}`}
                IconComponent={BiChevronDown}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      boxShadow: '0px 4px 12px #0000001F',
                    },
                  },
                }}
                sx={appointmentUi_style.selectSx}
              >
                {filter?.map((val, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => handleChange(val)}
                    value={val?.value}
                    sx={{
                      fontSize: '14px',
                      color: 'text.primary',
                      backgroundColor: '#fafafa',
                    }}
                    selected={open?.label === val?.label}
                  >
                    {open?.label === val?.label ? (
                      <TickIcon />
                    ) : (
                      <Box sx={{ width: '11px' }} />
                    )}
                    &nbsp;{val?.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        </Box>
      )}
      {loading &&
        Array(4)
          .fill(0)
          .map((i) => <DoctorModuleSkeleton key={i} />)}
      {!loading && doctorAppointmentData?.patientDetails?.length === 0 && (
        <Box sx={loading ? { display: 'none' } : ''}>
          <ClinicalEmptyState
            IconRequired={<NoAppointments />}
            emptyStateStyle={{ mt: 6 }}
            subHead="No appointments as of now"
            subHeadStyle={appointmentUi_style.subHeadSx}
          />
        </Box>
      )}
      <Box
        sx={
          loading
            ? { display: 'none' }
            : {
                ...(doctorAppointmentData?.patientDetails?.length > 0 && {
                  height: '90vh',
                }),
              }
        }
      >
        {Array.isArray(patientDetails) && patientDetails?.length > 0
          ? patientDetails?.map((val, index) => (
              <Box key={index}>
                {!isMobilePort ? (
                  <AppointmentListCard
                    viewSummaryClick={viewSummaryClick}
                    onClick={onClick}
                    handleClick={handleClick}
                    appointmentCancelLoading={appointmentCancelLoading}
                    data={val}
                    timeLapsed={timeLapsed}
                    resumeConsult={resumeConsult}
                    lastFiveMin={lastFiveMin}
                    currentTime={currentTime}
                    timerStartTime={timerStartTime}
                    index={index}
                    enableLoading={enableLoading}
                    loading={getAppointmentLoading}
                  />
                ) : (
                  <Box pb={1.5}>
                    <AppointmentMobileCard
                      viewSummaryClick={viewSummaryClick}
                      onClick={onClick}
                      handleClick={handleClick}
                      appointmentCancelLoading={appointmentCancelLoading}
                      data={val}
                      timeLapsed={timeLapsed}
                      resumeConsult={resumeConsult}
                      lastFiveMin={lastFiveMin}
                      currentTime={currentTime}
                      timerStartTime={timerStartTime}
                      index={index}
                      enableLoading={enableLoading}
                      loading={getAppointmentLoading}
                    />
                  </Box>
                )}
              </Box>
            ))
          : null}

        {cancelAppointment && (
          <Menu
            anchorEl={cancelAppointment}
            open={Boolean(cancelAppointment)}
            onClose={handleCancelClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={appointmentUi_style.morelistSx}
          >
            <MenuItem onClick={() => {}} sx={appointmentUi_style.borderSx}>
              <CancelIcon rootStyle={{ color: 'error.main' }} />
              <Box
                id="cancelAppoinment"
                loading={appointmentCancelLoading}
                component="span"
                sx={appointmentUi_style.cancelAppointment}
                onClick={() => cancelAppoinmentClick()}
              >
                Cancel Appointment
              </Box>
            </MenuItem>
          </Menu>
        )}
      </Box>
      <CancelAppointmentDialog
        isModalOpen={modalOpen}
        handleClose={handleModalClose}
        handleRemove={handleCancelAppointment}
      />
    </Box>
  );
}

AppointmentUi.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  patientDetails: PropTypes.array,
};
