export const profileMobilePage_style = {
  rootSx: {
    bgcolor: '#FDFFFF',
    height: '100vh',
  },
  cardRootSx: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 2,
    border: '1px solid #EEEEEE',
    borderRadius: '8px',
    mb: 1.5,
    cursor: 'pointer',
  },
};
