/* eslint-disable no-useless-concat */
import { useSummary } from '@hc/store';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { UserProfileIcon } from '../../../atoms';
import { SummaryAdvice } from '../summaryAdvice';
import { SummaryLabTests } from '../summaryLabTests';
import { SummaryObservation } from '../summaryObservation';
import { SummaryPrescription } from '../summaryPrescription';
import { SummaryRefer } from '../summaryRefer';
import { viewSummaryModel_style } from './style';

function ViewSummaryModel(props) {
  const { className = '', isMobile, rootStyle = {}, ...rest } = props;
  const { summaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
  }));

  const { appointmentData } = summaryState;
  const { patientName, patientGender, patientAge } = appointmentData;
  return (
    <Box
      sx={{
        ...viewSummaryModel_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack>
        <Box
          sx={{
            position: 'sticky',
            top: '0',
            opacity: '1',
            zIndex: '100',
            background: 'white',
            pb: 0,
          }}
        >
          <Grid container py={2}>
            <Grid item xs={1}>
              <Box sx={viewSummaryModel_style.avatarSx}>
                <Box alignSelf="center">
                  <UserProfileIcon />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={8} display="flex">
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    color="textPrimary"
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      fontWeight: 500,
                    }}
                  >
                    {patientName ?? ''}
                  </Typography>
                </Grid>

                <Grid item xs={12} display="flex">
                  <Typography
                    noWrap
                    sx={{
                      fontSize: '14px',
                      color: '#0F0B117A',
                      fontWeight: 500,
                    }}
                  >
                    {patientGender && patientAge
                      ? `${patientGender}` + ' ' + `, ${patientAge} Yrs`
                      : patientGender
                      ? `${patientGender}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box px={2} pb={1}>
          <SummaryObservation
            summaryModelView
            addTitle="Add Observation"
            summaryTitle="OBSERVATION"
          />
          <SummaryPrescription
            summaryModelView
            isMobile={isMobile}
            addTitle="Add Prescription"
            summaryTitle="PRESCRIPTION"
          />
          <SummaryLabTests
            summaryModelView
            addTitle="Add Lab Tests"
            summaryTitle="LAB TESTS"
          />
          <SummaryAdvice
            summaryModelView
            addTitle="Add Advice"
            summaryTitle="ADVICE"
          />
          <SummaryRefer
            summaryModelView
            addTitle="Refer to another doctor"
            summaryTitle="REFER"
          />
        </Box>
      </Stack>
    </Box>
  );
}

ViewSummaryModel.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ViewSummaryModel };
