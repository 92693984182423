export const viewSummaryReport_style = {
  totalStyle: {
    backgroundColor: 'transparent',
  },
  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    p: '10px 0px',
    width: '100%',
    color: '#3D3D3D',
    fontWeight: 500,
    backgroundColor: '#E3E3E3',
    borderRadius: '26px',
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: '#007965',
      borderRadius: '26px',
      fontSize: '14px',
      p: '6px 0px',
      fontWeight: 600,
    },
  },
  underTab: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'none',
      height: '0',
      px: 3,
    },

    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,1fr)',
      gap: '8px',
      boxSizing: 'border-box',
      my: 0.5,
    },
    '& .MuiTabs-root': {
      borderRadius: '0px',
    },
  },
  ReportHeadSx: {
    display: 'flex',
    alignItems: 'center',
    p: 1,
    backgroundColor: 'common.white',
  },
  closeSx: {
    width: '14px',
    height: '1epx',
    color: '#000',
  },
  root: {
    width: ' 1024px',
    margin: '0px auto',
  },

  toggleButton: {
    marginTop: '12px ',
    '& .MuiToggleButton-root': {
      border: 'none',
      backgroundColor: '#e2e2e2 !important',
      padding: '10px 21px',
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(0 121 101) !important',
    },
  },
  profileContainer: {
    // marginTop: theme.spacing(3),
  },
  adSx: {
    borderRadius: '8px',
  },
  reportTitle: {
    textAlign: 'left',
    // marginTop: '24px',
    color: 'common.black',
    fontWeight: 500,
  },
  profile: {
    background: '#fff',
    border: '1px solid #E3E3E3',
    // borderRadius: "8px",
    // opacity: "0.9",
  },
  profileInput: {
    // marginLeft: theme.spacing(3),
    padding: '32px',
    height: '100vh',
    backgroundColor: '#F5F5F5',
    overflowY: 'scroll',
  },
  search: {
    marginTop: '13px',
  },
  reportFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 4,
  },
  dropdown: {
    fontSize: '12px',
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  menuList: {
    fontSize: '12px',
  },
  sectionTwo: {
    backgroundColor: '#FAFAFA',
    padding: '24px 24px',
    height: '100vh',
    overflowX: 'scroll',
  },
  sectionOne: {
    // padding: '0px 24px',
  },
  reportFile: {
    margin: '0 auto',
    overFlow: 'scroll',
  },
  searchFiledSx: {
    '& .MuiOutlinedInput-root': {
      py: 0.3,
      color: '#C5C5C5',
      borderRadius: '8px',
      borderColor: '#DBEAE8',
      '&.Mui-focused fieldset': {
        borderColor: '#DBEAE8',
        color: '#0E1824',
        borderRadius: '8px',
      },
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      color: '#0E1824',
    },
  },
  filterSx: {
    fontSize: '14px',
    // maxWidth: '75px',
    '&.MuiOutlinedInput': {
      p: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0',
      borderColor: 'transparent',
      borderRadius: 'none',
    },
    '&: hover': {
      borderColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderWidth: '0px',
    },
  },
  nameSx: {
    fontSize: '14px',
    fontWeight: '500',
  },
  reportCardSx: {
    overflowY: 'scroll',
    height: '737px',
    padding: '0px 7px',
  },
  divider: {
    my: 2,
  },
};
