/* eslint-disable no-restricted-syntax */
import { useSummary } from '@hc/store';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { CustomTextArea } from '../../../atoms';
import {
  AddSummaryIcon,
  SummaryCloseIcon,
  SummaryEditIcon,
  SummaryTickIcon,
  SummaryTrashIcon,
} from '../../../atoms/icons/iconSet9';
import { summaryObservation_style } from './style';

function SummaryObservation(props) {
  const {
    summaryView = false,
    summaryModelView = false,
    summaryTitle = '',
    addTitle = '',
    className = '',
    rootStyle = {},
    ...rest
  } = props;

  // general Hooks
  const [inputShown, setInputShown] = useState(false);
  const [text, setText] = useState('');
  const [isEditIndex, setIsEditIndex] = useState(null);

  const { summaryState, addData, updateData, deleteData } = useSummary(
    (state) => ({
      summaryState: state.summaryState,
      addData: state.addData,
      updateData: state.updateData,
      deleteData: state.deleteData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { observationData, pastSummaryData } = summaryState;

  const { observation } = pastSummaryData;

  // Input open Common Func
  const commonOpenFun = () => {
    setInputShown(!inputShown);
  };

  // Input close icon
  const cancelInput = () => {
    setInputShown(false);
    setIsEditIndex(null);
    // CLEAR TEXT STATE DATA
    setText('');
  };

  // Input Value change func
  const handleChange = (value) => {
    setText(value);
  };

  // ADD OBSERVATION FUNCTION
  const handleAdd = () => {
    let result;
    const wordLength = text.split(' ');
    for (const val of wordLength) {
      if (val.length > 25) {
        result = false;
      } else {
        result = true;
      }
    }
    if (result) {
      if (text && text?.length > 0) {
        if (isEditIndex !== null) {
          // UPDATE OBSERVATION
          updateData('observationData', isEditIndex, text);
        } else {
          // ADD OBSERVATION
          addData('observationData', text);
        }

        setInputShown(false);
        // CLEAR TEXT STATE DATA
        setText('');
        setIsEditIndex(null);
      } else {
        toast.error('Please enter observation!');
      }
    } else {
      toast.error('Please check space between the words on notes!');
    }
  };

  // Input Value Edit Func
  const onEdit = (index) => {
    setIsEditIndex(index);
    const findValue = observationData?.filter((val, i) => i === index)?.[0];
    setText(findValue);
    setInputShown(true);
  };

  // DELETE OBSERVATION FUNCTION
  const onDelete = (i) => {
    // DELETE OBSERVATION
    deleteData('observationData', i);
  };

  return (
    <Box
      sx={{
        ...summaryObservation_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Summary Title */}
      <Typography sx={summaryObservation_style.addSummaryTitleSx}>
        {summaryTitle}
      </Typography>
      {!summaryModelView && !summaryView && (
        <>
          {/* Input Text Data Mapping */}
          {Array.isArray(observationData) && observationData?.length > 0
            ? observationData?.map((val, index) => {
                return (
                  <Box key={index}>
                    <Box sx={summaryObservation_style.inputValueSx}>
                      <Typography sx={summaryObservation_style.inputTextSx}>
                        {val}
                      </Typography>
                      <Box sx={summaryObservation_style.inputValueIconSx}>
                        <IconButton
                          disableRipple
                          onClick={() => onDelete(index)}
                        >
                          <SummaryTrashIcon />
                        </IconButton>
                        <IconButton disableRipple onClick={() => onEdit(index)}>
                          <SummaryEditIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Divider sx={summaryObservation_style.dividerSx} />
                  </Box>
                );
              })
            : null}
          {/* Add Summary Icon With Text */}
          {!inputShown && (
            <Box sx={summaryObservation_style.inputTextShowSx}>
              <IconButton disableRipple onClick={() => commonOpenFun()}>
                <AddSummaryIcon />
              </IconButton>
              <Typography sx={summaryObservation_style.summaryTitlesx}>
                {addTitle}
              </Typography>
            </Box>
          )}
          {inputShown && (
            <Box sx={summaryObservation_style.inputBoxSx}>
              <CustomTextArea
                fullWidth
                rows={1.8}
                rowsMax={5}
                textFieldStyle={{
                  width: '100%',
                  bgcolor: '#fff',
                }}
                sx={{
                  width: '100%',
                }}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                value={text}
              />
              <IconButton disableRipple onClick={() => cancelInput()}>
                <SummaryCloseIcon />
              </IconButton>
              <IconButton disableRipple onClick={() => handleAdd()}>
                <SummaryTickIcon />
              </IconButton>
            </Box>
          )}
        </>
      )}

      {summaryView && (
        <Box>
          {Array.isArray(observation) && observation?.length > 0 ? (
            observation?.map((val, i) => {
              return (
                <Box sx={summaryObservation_style.boxCardSx} key={i}>
                  <Typography sx={summaryObservation_style.valTextSx}>
                    {val ?? ''}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Box sx={summaryObservation_style.boxCardSx}>
              <Typography sx={summaryObservation_style.valTextSx}>
                No observation found!
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {summaryModelView && (
        <Box>
          {Array.isArray(observationData) && observationData?.length > 0 ? (
            observationData?.map((val, i) => {
              return (
                <Box key={i} sx={summaryObservation_style.boxCardSx}>
                  <Typography sx={summaryObservation_style.valTextSx}>
                    {val ?? ''}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Box sx={summaryObservation_style.boxCardSx}>
              <Typography sx={summaryObservation_style.valTextSx}>
                No observation found!
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

SummaryObservation.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  summaryTitle: PropTypes.string,
  addTitle: PropTypes.string,
  summaryView: PropTypes.bool,
  summaryModelView: PropTypes.bool,
};

export { SummaryObservation };
