import { getDateFormat } from '@hc/dayjs';
import { useSummary, useViewPort } from '@hc/store';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Button, DialogModal } from '../../../atoms';
import {
  AddSummaryIcon,
  SummaryEditIcon,
  SummaryTrashIcon,
} from '../../../atoms/icons/iconSet9';
import { AddFollowup } from '../addFollowup';
import { summaryFollowUp_style } from './style';

function SummaryFollowUp(props) {
  const {
    summaryTitle = '',
    addTitle = '',
    className = '',
    rootStyle = {},
    summaryView = false,
    ...rest
  } = props;

  // General Hooks
  const [inputShown, setInputShown] = useState(false);

  const [isEditIndex, setIsEditIndex] = useState(null);

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { summaryState, updateSummaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
    updateSummaryState: state.updateSummaryState,
  }));

  const { followUp, pastSummaryData } = summaryState;

  const { followup } = pastSummaryData;

  // commonInput Open func
  const commonOpenFun = () => {
    setInputShown(!inputShown);
  };

  // Handle Add input func
  const saveFollowupFnc = () => {
    if (followUp && followUp?.toString()?.length > 0) {
      setInputShown(false);
    } else {
      toast.error('Please select follow-up date!');
    }
  };

  // Input Value Edit Func
  const onEdit = () => {
    setIsEditIndex(1);
    setInputShown(true);
  };

  // DELETE FUNCTION
  const onDelete = () => {
    // DELETE
    updateSummaryState('followUp', null);
  };

  // Close func
  const handleClose = () => {
    if (isEditIndex !== 1) {
      updateSummaryState('followUp', null);
    }
    setInputShown(false);
  };

  return (
    <Box
      sx={{
        ...summaryFollowUp_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* Add Title */}
      <Typography sx={summaryFollowUp_style.addSummaryTitleSx}>
        {summaryTitle}
      </Typography>
      {!summaryView && (
        <>
          {/* Input Text Data Mapping */}

          {followUp && (
            <>
              <Box sx={summaryFollowUp_style.inputValueSx}>
                <Typography sx={summaryFollowUp_style.inputTextSxx}>
                  {followUp ? getDateFormat(followUp, 'YYYY-MM-DD') : ''}
                </Typography>
                <Box sx={summaryFollowUp_style.inputValueIconSx}>
                  <IconButton disableRipple onClick={() => onDelete()}>
                    <SummaryTrashIcon />
                  </IconButton>
                  <IconButton disableRipple onClick={() => onEdit()}>
                    <SummaryEditIcon />
                  </IconButton>
                </Box>
              </Box>
              {/* <Divider sx={summaryFollowUp_style.dividerSx} /> */}
            </>
          )}
          {/* Add Summary Icon With Text */}
          {!inputShown && !followUp && (
            <Box sx={summaryFollowUp_style.inputTextShowSx}>
              <IconButton disableRipple onClick={() => commonOpenFun()}>
                <AddSummaryIcon />
              </IconButton>
              <Typography sx={summaryFollowUp_style.summaryTitlesx}>
                {addTitle}
              </Typography>
            </Box>
          )}
          {inputShown && (
            <Box>
              <DialogModal
                maxModalWidth="xl"
                topDivider
                bottomDivider
                isBackRequired={isMobilePort ? true : false}
                isCloseOut={isMobilePort ? false : true}
                isMobileResponsive={isMobilePort}
                title="Select follow-up date"
                dialogRootStyle={{
                  width: '490px',
                }}
                titleStyle={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: 'text.primary',
                }}
                content={<AddFollowup />}
                actions={
                  <Grid
                    p={1}
                    container
                    columnSpacing={1.5}
                    justifyContent={isMobilePort ? 'space-around' : 'end'}
                  >
                    {isMobilePort && (
                      <Grid item xs={6}>
                        <Button onClick={handleClose} variant="outlined">
                          Cancel
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={isMobilePort ? 6 : 6}>
                      <Button onClick={() => saveFollowupFnc()}>
                        {' '}
                        ADD Follow-up
                      </Button>
                    </Grid>
                  </Grid>
                }
                isDialogOpened={inputShown}
                handleCloseDialog={handleClose}
              />
            </Box>
          )}
        </>
      )}

      {summaryView &&
        (followup ? (
          <Box
            // key={i}
            sx={{
              bgcolor: '#F5F5F5',
              borderRadius: '8px',
              py: 1.5,
              px: 2,
              mb: 1.5,
            }}
          >
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {followup}
            </Typography>
          </Box>
        ) : (
          <Box sx={summaryFollowUp_style.boxCardSx}>
            <Typography sx={summaryFollowUp_style.valTextSx}>
              No Follow-up found!
            </Typography>
          </Box>
        ))}
    </Box>
  );
}

SummaryFollowUp.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  summaryTitle: PropTypes.string,
  viewSummaryTitle: PropTypes.string,
  summaryView: PropTypes.bool,
  addTitle: PropTypes.string,
};

export { SummaryFollowUp };
