import { AddSolidIcon, CloseIcon, Input, SearchIcon } from '@atoms';
import { useViewPort } from '@hc/store';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { RxGroupSkeleton } from '@hc/ui/components';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DrugsGroupCard } from '../drugsGroupCard';
import { drugsGroupList_style } from './style';

function DrugsGroupList(props) {
  const {
    className = '',
    rootStyle = {},
    setOnSearchFnc = () => {},
    handleSearchClear = () => {},

    handleEditOpen = () => {},
    handleAddOpen = () => {},
    data = [],
    cardResponse,
    onChange,
    handleBoxClickFnc,
    search = '',
    ...rest
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { rxGroupState, loading } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    loading: state.loading,
  }));
  const { rxGroupId } = rxGroupState;

  return (
    <Box
      sx={{
        ...drugsGroupList_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
      px={1}
    >
      <Grid container>
        <Grid pb={1.5} pt={1} item xs={12}>
          <Grid container display="flex" justifyContent="space-between">
            {!isMobilePort && (
              <Grid item>
                <Typography fontWeight={600} pl={1}>
                  Rx Group
                </Typography>
              </Grid>
            )}

            {!isMobilePort && (
              <Grid item>
                <Box
                  id="addRxGroup"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleAddOpen()}
                >
                  <AddSolidIcon rootStyle={{ color: '#007965' }} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid pb={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Input
            placeholder="Search"
            textFieldStyle={drugsGroupList_style.searchFiledSx}
            onChange={(e) => setOnSearchFnc(e.target.value)}
            value={search}
            startAdornment={!search?.length > 0 && <SearchIcon />}
            endAdornment={
              search?.length > 0 && (
                <Box onClick={handleSearchClear} sx={{ cursor: 'pointer' }}>
                  <CloseIcon />
                </Box>
              )
            }
          />
        </Grid>
        {Array.isArray(data) && data?.length > 0 && !loading && (
          <Grid item xs={12}>
            <Box
              sx={{
                height: cardResponse ? '70vh' : '75vh',
                pb: cardResponse ? 5 : 0,
                overflow: 'scroll',
                scrollbarWidth: '0px',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              {data.map((val, i) => (
                <Box key={i}>
                  <DrugsGroupCard
                    data_testId={`card${i + 1}`}
                    groupName={val?.rx_data?.rx_group_name ?? ''}
                    drugsCount={val?.drug_count ?? 0}
                    status={val?.rx_data?.is_active ?? false}
                    onChange={(e) => onChange(e, val)}
                    onClick={() => handleBoxClickFnc(val, i)}
                    isActive={val?.rx_data?.id === rxGroupId}
                    handleEditOpen={handleEditOpen}
                    data={val}
                    index={i}
                    length={data?.length ?? 0}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        )}{' '}
        {Array.isArray(data) && data?.length === 0 && !loading && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box sx={{ overflowY: 'auto', height: '100vh' }}>
              <Box px={2}>
                <Typography
                  textAlign="center"
                  py={15}
                  color="#616161"
                  fontWeight={600}
                >
                  No Rx Group Found!
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}
        {loading &&
          Array(6)
            .fill(0)
            .map((i) => <RxGroupSkeleton key={i} />)}
      </Grid>
    </Box>
  );
}

DrugsGroupList.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  setOnSearchFnc: PropTypes.func,
  handleSearchClear: PropTypes.func,
  handleEditOpen: PropTypes.func,
  handleAddOpen: PropTypes.func,
  data: PropTypes.array,
  cardResponse: PropTypes.any,
  onChange: PropTypes.any,
  handleBoxClickFnc: PropTypes.any,
  search: PropTypes.any,
};

export { DrugsGroupList };
