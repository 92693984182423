import { getAppointmentTime, getDateFormat } from '@hc/dayjs';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { RiMore2Fill } from 'react-icons/ri';
import {
  AppointmentHeaders,
  Button,
  PhoneIcon,
  ScheduleCalender,
  UserProfileIcon,
} from '../../../atoms';
import { appointmentListCard_style } from './style';

function AppointmentListCard(props) {
  const {
    className = '',
    rootStyle = {},
    viewSummaryClick = () => {},
    onClick = () => {},
    handleClick = () => {},
    appointmentCancelLoading,
    data = {},
    timeLapsed,
    lastFiveMin,
    index,
    resumeConsult,
    enableLoading,
    currentTime,
    timerStartTime,
    loading,
    ...rest
  } = props;
  const [timer, setTimer] = useState();

  useEffect(() => {
    const myIntervalll = setInterval(() => {
      if (
        getAppointmentTime(data?.appointment_time_slot, 'HH:mm:ss') <=
        currentTime
      ) {
        const countDownDate = new Date(data?.appointment_date_time).getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimer(`${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => {
      clearInterval(myIntervalll);
    };
  }, [currentTime]);

  const selectedIndex = localStorage.getItem('index');

  return (
    <Box
      sx={{
        ...appointmentListCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={appointmentListCard_style.cardSx}
        key={data.id}
        id={`appointmentCard${index + 1}`}
      >
        <Grid container direction="row" justify="center" spacing={1}>
          <Grid sx={appointmentListCard_style.gridcenterSx} item sm={3} xs={12}>
            <AppointmentHeaders
              isFollowUP={data?.appointment_type_id === 1 ? 'Follow-up' : ''}
              isReferral={data?.appointment_type_id === 2 ? 'Referral' : ''}
              title={<span style={{ fontSize: '16px' }}>{data?.name}</span>}
              subText={data?.gender}
              age={data?.age}
              profileIcon={<UserProfileIcon />}
            />
          </Grid>
          <Grid sx={appointmentListCard_style.gridcenterSx} item sm={3} xs={12}>
            <AppointmentHeaders
              profileIcon={<PhoneIcon />}
              title={data?.mobile}
            />
          </Grid>
          <Grid sx={appointmentListCard_style.gridcenterSx} item sm={3} xs={12}>
            <AppointmentHeaders
              profileIcon={<ScheduleCalender />}
              title={
                data?.appointment_type_id === 1
                  ? 'Follow-Up'
                  : data?.appointment_type_id === 2
                  ? 'Referral'
                  : 'New Appointment'
              }
              subText={
                getDateFormat(new Date(), 'YYYY-MM-DD') >=
                data?.appointment_date ? (
                  data?.master_appointment_status?.appointment_status ===
                    'booked' ||
                  data?.master_appointment_status?.appointment_status ===
                    'InProgress' ? (
                    getAppointmentTime(
                      data?.appointment_time_slot,
                      'HH:mm:ss'
                    ) <= timeLapsed ? (
                      <Typography
                        variant="caption"
                        sx={appointmentListCard_style.referchangingTESx}
                      >
                        Time Elapsed
                      </Typography>
                    ) : getAppointmentTime(
                        data?.appointment_time_slot,
                        'HH:mm:ss'
                      ) <= lastFiveMin ? (
                      <Typography
                        variant="caption"
                        sx={appointmentListCard_style.referchangingSx}
                      >
                        Live
                      </Typography>
                    ) : getAppointmentTime(
                        data?.appointment_time_slot,
                        'HH:mm:ss'
                      ) <= currentTime ? (
                      <Typography
                        variant="caption"
                        sx={appointmentListCard_style.referchangingSx}
                      >
                        {/* {timer} */}
                        in {timer}
                      </Typography>
                    ) : (
                      getAppointmentTime(
                        data?.appointment_time_slot,
                        'HH:mm:ss'
                      )
                    )
                  ) : data?.master_appointment_status?.appointment_status ===
                    'canceled' ? (
                    <Typography
                      variant="caption"
                      sx={appointmentListCard_style.referchangingTESx}
                    >
                      Canceled
                    </Typography>
                  ) : data?.master_appointment_status?.appointment_status ===
                      'timeLapsed' ||
                    data?.master_appointment_status?.appointment_status ===
                      'booked' ||
                    (data?.master_appointment_status?.appointment_status ===
                      'InProgress' &&
                      getAppointmentTime(
                        data?.appointment_time_slot,
                        'HH:mm:ss'
                      ) <= timeLapsed) ? (
                    <Typography
                      variant="caption"
                      sx={appointmentListCard_style.referchangingTESx}
                    >
                      Time Elapsed
                    </Typography>
                  ) : data?.master_appointment_status?.appointment_status ===
                    'completed' ? (
                    <Typography
                      variant="caption"
                      sx={appointmentListCard_style.referchangingSx}
                    >
                      Completed
                    </Typography>
                  ) : (
                    ''
                  )
                ) : (
                  getAppointmentTime(data?.appointment_time_slot, 'HH:mm:ss')
                )
              }
            />
          </Grid>

          <Grid sx={appointmentListCard_style.gridcenterSx} item sm={2}>
            {data?.master_appointment_status?.appointment_status ===
            'completed' ? (
              <Button
                type="submit"
                buttonStyle={appointmentListCard_style.buttonSx}
                onClick={() => viewSummaryClick(data, index)}
                variant="outlined"
                color="primary"
                fontSize="14px"
                loading={enableLoading === index ? loading : false}
              >
                View summary
              </Button>
            ) : (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => onClick(data, index)}
              >
                <Button
                  id={`startConsult${index + 1}`}
                  type="submit"
                  buttonStyle={appointmentListCard_style.buttonSx}
                  onClick={() => onClick(data, index)}
                  variant="outlined"
                  color="primary"
                  fontSize="14px"
                  loading={enableLoading === index ? loading : false}
                  disabled={
                    data?.master_appointment_status?.appointment_status ===
                      'booked' ||
                    data?.master_appointment_status?.appointment_status ===
                      'InProgress'
                      ? getAppointmentTime(
                          data?.appointment_time_slot,
                          'HH:mm:ss'
                        ) <= timeLapsed
                        ? false
                        : getAppointmentTime(
                            data?.appointment_time_slot,
                            'HH:mm:ss'
                          ) <= lastFiveMin
                        ? false
                        : getAppointmentTime(
                            data?.appointment_time_slot,
                            'HH:mm:ss'
                          ) <= currentTime
                        ? false
                        : true
                      : data?.master_appointment_status?.appointment_status ===
                        'canceled'
                      ? true
                      : false
                  }
                >
                  {data?.master_appointment_status?.appointment_status ===
                    'booked' ||
                  data?.master_appointment_status?.appointment_status ===
                    'InProgress'
                    ? getAppointmentTime(
                        data?.appointment_time_slot,
                        'HH:mm:ss'
                      ) <= timeLapsed
                      ? 'Add summary'
                      : data?.appointment_id === selectedIndex
                      ? 'continue'
                      : 'start consult'
                    : data?.master_appointment_status?.appointment_status ===
                      'timeLapsed'
                    ? 'Add summary'
                    : data?.appointment_id === selectedIndex
                    ? 'continue'
                    : 'start consult'}
                </Button>
              </Box>
            )}
          </Grid>
          <Grid
            sx={{
              justifyContent: 'center',
              display: 'flex',
              aligndatas: 'center',
            }}
            item
            sm={1}
          >
            <IconButton
              id={`more${index + 1}`}
              sx={{ p: 0 }}
              disableRipple
              onClick={(e) => handleClick(e, data)}
              disabled={
                data?.master_appointment_status?.appointment_status === 'booked'
                  ? getAppointmentTime(
                      data?.appointment_time_slot,
                      'HH:mm:ss'
                    ) > currentTime
                    ? false
                    : true
                  : data?.master_appointment_status?.appointment_status ===
                    'InProgress'
                  ? getAppointmentTime(
                      data?.appointment_time_slot,
                      'HH:mm:ss'
                    ) > currentTime
                    ? false
                    : true
                  : true
              }
            >
              <RiMore2Fill
                style={{
                  fontSize: '20px',
                  color: 'grey.800',
                  height: '18px',
                  '&:hover': {
                    backgroundColor: 'common.white',
                  },
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

AppointmentListCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  viewSummaryClick: PropTypes.func,
  onClick: PropTypes.func,
  handleClick: PropTypes.func,
  appointmentCancelLoading: PropTypes.bool,
  data: PropTypes.object,
  timeLapsed: PropTypes.string,
  lastFiveMin: PropTypes.string,
  timerStartTime: PropTypes.string,
  currentTime: PropTypes.string,
  index: PropTypes.number,
  resumeConsult: PropTypes.any,
  enableLoading: PropTypes.any,
};

export { AppointmentListCard };
