import { Button, DialogModal } from '@atoms';
import { useMasterDurationMode } from '@hc/store/doctor/masterDurationMode';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Drawer, Input, ToggleButton } from '../../../../atoms';
import { prescriptionDurationModal_style } from './style';

function PrescriptionDurationModal(props) {
  const {
    className = '',
    isModalOpen,
    handleClose,
    durationQuantityData,
    durationQuantityChange,
    handleDurationQuantitySave,
    isMobileResponsive,
    rootStyle = {},
    ...rest
  } = props;

  const { getMasterDurationModeData, masterDurationModeData } =
    useMasterDurationMode((state) => ({
      getMasterDurationModeData: state.getMasterDurationModeData,
      masterDurationModeData: state.masterDurationModeData,
    }));

  const DurationList = [
    {
      name: '1',
      value: 1,
    },
    {
      name: '2',
      value: 2,
    },
    {
      name: '3',
      value: 3,
    },
    {
      name: '4',
      value: 4,
    },
    {
      name: '5',
      value: 5,
    },
    {
      name: '6',
      value: 6,
    },
  ];

  const initialMaterData = async () => {
    await getMasterDurationModeData();
  };

  useEffect(() => {
    initialMaterData();
  }, []);

  return (
    <Box
      sx={{
        ...prescriptionDurationModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!isMobileResponsive && (
        <DialogModal
          title="Duration & Qty"
          titleStyle={prescriptionDurationModal_style.titleSx}
          maxModalWidth={750}
          topDivider
          bottomDivider
          dialogRootStyle={prescriptionDurationModal_style.dialogSx}
          content={
            <Stack>
              <Grid container alignItems="center">
                <Grid item xs={5}>
                  <Typography mb={1} sx={{ fontSize: '16px', fontWeight: 600 }}>
                    Duration
                  </Typography>
                  <ToggleButton
                    options={DurationList}
                    value={durationQuantityData?.duration}
                    buttonactiveStyle={
                      prescriptionDurationModal_style.buttonactivenumSX
                    }
                    buttoninactiveStyle={
                      prescriptionDurationModal_style.buttoninactivenumSX
                    }
                    onChange={(e) => {
                      durationQuantityChange('duration', e);
                    }}
                  />
                </Grid>

                <Divider
                  sx={prescriptionDurationModal_style.dividerSx}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <Grid item xs={6} mt={3.5} pl={2}>
                  <ToggleButton
                    options={masterDurationModeData ?? []}
                    buttonactiveStyle={
                      prescriptionDurationModal_style.buttonactivedaySX
                    }
                    buttoninactiveStyle={
                      prescriptionDurationModal_style.buttoninactivedaySX
                    }
                    // nameActiveStyle={
                    //   prescriptionDurationModal_style.activeToggleTextSx
                    // }
                    // nameInactiveStyle={
                    //   prescriptionDurationModal_style.inActiveToggleTextSx
                    // }
                    value={durationQuantityData?.durationMode?.value}
                    data={durationQuantityData?.durationMode}
                    onChange={(e, name) => {
                      durationQuantityChange('durationMode', e, name?.name);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item>
                  <Button
                    p={0}
                    // sx={prescriptionDurationModal_style.buttonSx}
                  >
                    Custom
                  </Button>
                </Grid>
                <Grid item ml={1}>
                  <Box sx={prescriptionDurationModal_style.countButtonSx}>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() =>
                        durationQuantityData?.customDuration > 0
                          ? durationQuantityChange('customDuration_decrease')
                          : null
                      }
                    >
                      -
                    </IconButton>
                    <Box
                      sx={prescriptionDurationModal_style.countButtonMideleSx}
                    >
                      <Typography>
                        {durationQuantityData?.customDuration ?? 0}
                      </Typography>
                    </Box>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() =>
                        durationQuantityChange('customDuration_increase')
                      }
                    >
                      +
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Box pt={2}>
                <Typography pb={1}>Qty</Typography>
                <Input
                  size="small"
                  value={durationQuantityData?.quantity ?? 0}
                  fullwidth={false}
                  textFieldStyle={prescriptionDurationModal_style.QtyTypoSx}
                  endAdornment={
                    <Typography sx={{ fontSize: '14px', fontWeigt: 500 }}>
                      nos
                    </Typography>
                  }
                />
              </Box>
            </Stack>
          }
          actions={
            <Grid p={1} spacing={1} container justifyContent="end">
              <Grid item>
                <Button
                  sx={prescriptionDurationModal_style.buttonSx}
                  onClick={handleClose}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleDurationQuantitySave}>SAVE</Button>
              </Grid>
            </Grid>
          }
          isDialogOpened={isModalOpen}
          handleCloseDialog={handleClose}
        />
      )}

      {isMobileResponsive && (
        <Drawer
          show={isModalOpen}
          header="Duration & Qty"
          drawerRightClose
          onCloseDrawer={handleClose}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          rootStyle={prescriptionDurationModal_style.drawerSx}
          closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={handleClose}
                buttonStyle={prescriptionDurationModal_style.cancelBtnSx}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleDurationQuantitySave}
                buttonStyle={prescriptionDurationModal_style.applyBtnSx}
              >
                SAVE
              </Button>
            </Box>
          }
        >
          <Stack>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography mb={1} sx={{ fontSize: '16px', fontWeight: 600 }}>
                  Duration
                </Typography>
                <ToggleButton
                  options={DurationList}
                  value={durationQuantityData?.duration}
                  buttonactiveStyle={
                    prescriptionDurationModal_style.buttonactivenumSX
                  }
                  buttoninactiveStyle={
                    prescriptionDurationModal_style.buttoninactivenumSX
                  }
                  onChange={(e) => {
                    durationQuantityChange('duration', e);
                  }}
                />
              </Grid>
              <Grid container mt={1}>
                <Grid item>
                  <Button
                    p={0}
                    // sx={prescriptionDurationModal_style.buttonSx}
                  >
                    Custom
                  </Button>
                </Grid>
                <Grid item ml={1}>
                  <Box sx={prescriptionDurationModal_style.countButtonSx}>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() =>
                        durationQuantityData?.customDuration > 0
                          ? durationQuantityChange('customDuration_decrease')
                          : null
                      }
                    >
                      -
                    </IconButton>
                    <Box
                      sx={prescriptionDurationModal_style.countButtonMideleSx}
                    >
                      <Typography>
                        {durationQuantityData?.customDuration ?? 0}
                      </Typography>
                    </Box>
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      onClick={() =>
                        durationQuantityChange('customDuration_increase')
                      }
                    >
                      +
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={prescriptionDurationModal_style.dividerSx} />

            <Grid container>
              <Grid item xs={12} mt={1}>
                <ToggleButton
                  options={masterDurationModeData ?? []}
                  buttonactiveStyle={
                    prescriptionDurationModal_style.buttonactivedaySX
                  }
                  buttoninactiveStyle={
                    prescriptionDurationModal_style.buttoninactivedaySX
                  }
                  // nameActiveStyle={
                  //   prescriptionDurationModal_style.activeToggleTextSx
                  // }
                  // nameInactiveStyle={
                  //   prescriptionDurationModal_style.inActiveToggleTextSx
                  // }
                  value={durationQuantityData?.durationMode?.value}
                  data={durationQuantityData?.durationMode}
                  onChange={(e, name) => {
                    durationQuantityChange('durationMode', e, name?.name);
                  }}
                />
              </Grid>
            </Grid>
            <Box pt={2}>
              <Typography pb={1}>Qty</Typography>
              <Input
                size="small"
                value={durationQuantityData?.quantity ?? 0}
                fullwidth={false}
                textFieldStyle={prescriptionDurationModal_style.QtyTypoSx}
                endAdornment={
                  <Typography sx={{ fontSize: '14px', fontWeigt: 500 }}>
                    nos
                  </Typography>
                }
              />
            </Box>
          </Stack>
        </Drawer>
      )}
    </Box>
  );
}

PrescriptionDurationModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { PrescriptionDurationModal };
