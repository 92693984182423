/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { getDateFormat, getTimeZoneFnc, momentUtc } from '@hc/dayjs';
import { doctorRoutes } from '@hc/routes';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
  routeTo
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const constructSymptomData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      let obj = {
        value: val?.symptom_id ?? '',
        label: val?.master_symptom?.symptoms ?? '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const constructSpecialityData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      let obj = {
        value: val?.master_speciality?.id ?? '',
        label: val?.master_speciality?.speciality ?? '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const constructHealthProblemData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      let obj = {
        value: val?.health_problem_id ?? '',
        label: val?.master_health_problem?.health_proble ?? '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const constructLabTest = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      arr.push(val);
    });
  }
  return arr;
};

export const constructPrescriptionData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      let obj = {
        drug_id: val?.drugMasterId ?? 0,
        drug: val?.drugName ?? '',
        dose: `${val?.morningDose}-${val?.afterNoonDose}-${val?.nightDose}`,
        time: val?.time?.label ?? '',
        when: val?.when?.label ?? '',
        frequency: val?.frequency?.label ?? '',
        duration: val?.duration ? val?.duration.toString() : '',
        duration_mode: val?.durationMode?.label ?? '',
        quantity: val?.quantity ? val?.quantity.toString() : '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const constructPastSummaryPrescriptionData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val, i) => {
      let obj = {
        drug_id: val?.drug_id ?? 0,
        drug: val?.drug ?? '',
        dose: val?.dose ?? '',
        time: val?.time ?? '',
        when: val?.when ?? '',
        frequency: val?.frequency ?? '',
        duration: val?.duration ?? '',
        duration_mode: val?.duration_mode ?? '',
        quantity: val?.quantity ?? '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const constructLabTestDetails = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    return {
      labTestArray:
        Array.isArray(data?.[0]?.labTestArray) &&
        data?.[0]?.labTestArray?.length > 0
          ? constructLabTest(data?.[0]?.labTestArray)
          : [],
      when: '',
      custom: data?.[0]?.labTestDate
        ? getDateFormat(data?.[0]?.labTestDate, 'YYYY-MM-DD')
        : '',
      note: data?.[0]?.note ?? '',
      labTestDate: data?.[0]?.labTestDate
        ? getDateFormat(data?.[0]?.labTestDate, 'YYYY-MM-DD')
        : '',
      labTestDateTime: momentUtc(data?.[0]?.labTestDate),
    };
  }
  return {};
};

export const constructPatientSymptomData = (data1, data2) => {
  const arr = data1;
  if (Array.isArray(data2) && data2?.length > 0) {
    data2.map((val, i) => {
      arr.push(val?.master_symptom?.symptoms);
    });
  }
  return arr;
};

export const constructPatientProblemData = (data1, data2) => {
  const arr = data1;
  if (Array.isArray(data2) && data2?.length > 0) {
    data2.map((val, i) => {
      arr.push(val?.master_health_problem?.health_proble);
    });
  }
  return arr;
};

export const useSummary = create((set, get) => ({
  summaryState: {
    patient_symptoms: [],
    patient_problems: [],
    patient_vitals: {},
    appointmentData: {
      id: '',
      appoinmentId: '',
      appointmentProfileId: '',
      doctorId: '',
      bookedBy: '',
      appointmentStatus: '',
      // PATIENT ID
      patientType: '',
      patientId: '',
      follow_up_appointment: '',
      referral_appointment: '',
      referralId: '',
      pastSummaryId: '',
      patientName: '',
      patientAge: '',
      patientGender: '',
      patientMobileNumber: '',
      meetUrl: '',
      patientCountryCode: '',
      isBookedByDoctor: null,
      healthIssue: [],
      symptom: [],
      problem: [],
      reportCount: '',
      vitals: {
        height: '',
        weight: '',
        temprature: '',
        pulse: '',
        bloodPressure: '',
        o2: '',
      },
    },
    summaryType: '',
    observationData: [],
    adviceData: [],
    prescriptionData: [],
    prescriptionNotesInstruction: '',
    referToDoctorModelDetails: {
      doctorSpeciality: '',
      doctorName: '',
      doctorId: '',
      doctorMobileNumber: '',
      doctorCountryCode: '+91',
      // doctorName: '',
      notes: '',
    },
    referToDoctorDetails: {
      doctorSpeciality: '',
      doctorName: '',
      doctorId: '',
      doctorMobileNumber: '',
      doctorCountryCode: '+91',
      notes: '',
    },
    referralNoteData: {
      doctorName: '',
      speciality: [],
      doctorMobileNumber: '',
      doctorCountryCode: '',
      regNo: '',
      hospitalName: [],
      referralNoteDate: '',
      patientName: '',
      patientAge: '',
      patientGender: '',
      referralNotes: '',
    },
    pastSummaryData: {
      doctorDetails: {
        doctorName: '',
        speciality: [],
        mobileNo: '',
        countryCOde: '',
        regNo: '',
        hospital: [],
      },
      appointmentDate: '',
      observation: [],
      prescription: [],
      labTest: [],
      advice: [],
      followup: '',
      referralNoteData: {
        doctorName: '',
        speciality: [],
        doctorMobileNumber: '',
        doctorCountryCode: '',
        regNo: '',
        hospitalName: [],
        referralNoteDate: '',
        referralNotes: '',
      },
    },
    labTest: [],
    followUp: null,
    isNewDoctor: false,
  },
  labTestDetails: {
    labTestArray: [],
    labTestDate: '',
    note: '',
  },
  loading: null,
  error: null,

  clearSummaryState: () => {
    set({
      summaryState: {
        patient_symptoms: [],
        patient_problems: [],
        patient_vitals: {},
        appointmentData: {
          id: '',
          appointmentProfileId: '',
          doctorId: '',
          bookedBy: '',
          appointmentStatus: '',
          // PATIENT ID
          patientType: '',
          patientId: '',
          follow_up_appointment: '',
          referral_appointment: '',
          referralId: '',
          pastSummaryId: '',
          patientName: '',
          patientAge: '',
          patientGender: '',
          patientMobileNumber: '',
          patientCountryCode: '',
          healthIssue: [],
          symptom: [],
          problem: [],
          isBookedByDoctor: null,
          reportCount: '',
          vitals: {
            height: '',
            weight: '',
            temprature: '',
            pulse: '',
            bloodPressure: '',
            o2: '',
          },
        },
        summaryType: '',
        observationData: [],
        adviceData: [],
        prescriptionData: [],
        prescriptionNotesInstruction: '',
        referToDoctorModelDetails: {
          doctorSpeciality: '',
          doctorName: '',
          doctorId: '',
          doctorMobileNumber: '',
          doctorCountryCode: '+91',
          // doctorName: '',
          notes: '',
        },
        referToDoctorDetails: {
          doctorSpeciality: '',
          doctorName: '',
          doctorId: '',
          doctorMobileNumber: '',
          doctorCountryCode: '+91',
          notes: '',
        },
        referralNoteData: {
          doctorName: '',
          speciality: [],
          doctorMobileNumber: '',
          doctorCountryCode: '',
          regNo: '',
          hospitalName: [],
          referralNoteDate: '',
          patientName: '',
          patientAge: '',
          patientGender: '',
          referralNotes: '',
        },
        pastSummaryData: {
          doctorDetails: {
            doctorName: '',
            speciality: [],
            mobileNo: '',
            countryCOde: '',
            regNo: '',
            hospital: [],
          },
          appointmentDate: '',
          observation: [],
          prescription: [],
          labTest: [],
          advice: [],
          followup: '',
          referralNoteData: {
            doctorName: '',
            speciality: [],
            doctorMobileNumber: '',
            doctorCountryCode: '',
            regNo: '',
            hospitalName: [],
            referralNoteDate: '',
            referralNotes: '',
          },
        },
        labTest: [],
        followUp: null,
        isNewDoctor: false,
      },
    });
  },
  setLocalStorage: () => {
    const { summaryState } = get();
    localStorage.setItem('summaryState', JSON.stringify(summaryState));
  },
  getLocalStorage: () => {
    const data = localStorage.getItem('summaryState');
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        summaryState: jsonData,
      });
    }
  },

  updateLabTestFullDetails: (value) => {
    const { setLocalStorage } = get();
    set({
      labTestDetails: value,
    });
    setLocalStorage();
  },

  deleteLabTestData: () => {
    const { summaryState, setLocalStorage } = get();
    set({
      summaryState: {
        ...summaryState,
        labTest: [],
      },
    });
    setLocalStorage();
  },
  saveLabTest: () => {
    const { summaryState, labTestDetails, setLocalStorage } = get();
    const arr = [];
    arr.push(labTestDetails);
    set({
      summaryState: {
        ...summaryState,
        labTest: arr,
      },
    });
    setLocalStorage();
    return 'Success';
  },
  updateLabTestDetails: (key, value) => {
    const { labTestDetails, setLocalStorage } = get();
    set({
      labTestDetails: {
        ...labTestDetails,
        [key]: value,
      },
    });
    setLocalStorage();
  },
  // ADD DATA
  addData: (key, text) => {
    const { summaryState, setLocalStorage } = get();
    const copyData = JSON.parse(JSON.stringify(summaryState?.[key]));
    copyData.push(text);
    set({
      summaryState: {
        ...summaryState,
        [key]: copyData,
      },
    });
    setLocalStorage();
  },
  // UPDATE DATA
  updateData: (key, index, value) => {
    const { summaryState, setLocalStorage } = get();
    const copyData = JSON.parse(JSON.stringify(summaryState?.[key]));
    copyData[index] = value;
    set({
      summaryState: {
        ...summaryState,
        [key]: copyData,
      },
    });
    setLocalStorage();
  },
  updateReferToData: (key, value) => {
    const { summaryState, setLocalStorage } = get();
    const { referToDoctorModelDetails } = summaryState;
    set({
      summaryState: {
        ...summaryState,
        referToDoctorModelDetails: {
          ...referToDoctorModelDetails,
          [key]: value,
        },
      },
    });
    setLocalStorage();
  },

  // DELETE DATA
  deleteData: (type, i) => {
    const { summaryState, setLocalStorage } = get();
    const copyData = JSON.parse(JSON.stringify(summaryState?.[type]));
    copyData.splice(i, 1);
    set({
      summaryState: {
        ...summaryState,
        [type]: copyData,
      },
    });
    setLocalStorage();
  },

  getReferralNoteData: async (referralId) => {
    try {
      const { summaryState, setLocalStorage } = get();
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['viewReferral'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/view-referral`,
            {
              referral_id: '',
              appointment_id:
                summaryState?.appointmentData?.referral_appointment ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          summaryState: {
            ...summaryState,
            referralNoteData: {
              doctorName: data?.doctor_data?.first_name
                ? data?.doctor_data?.first_name
                : data?.doctor_data?.name
                ? data?.doctor_data?.name
                : '',
              speciality:
                Array.isArray(data?.doctor_data?.doctor_specialities) &&
                data?.doctor_data?.doctor_specialities?.length > 0
                  ? data?.doctor_data?.doctor_specialities
                  : [],
              doctorMobileNumber: data?.doctor_data?.mobile_no ?? '',
              doctorCountryCode:
                data?.doctor_data?.mobile_no_country_code ?? '',
              regNo: data?.doctor_data?.register_no ?? '',
              hospitalName:
                Array.isArray(data?.doctor_data?.working_at) &&
                data?.doctor_data?.working_at?.length > 0
                  ? data?.doctor_data?.working_at
                  : [],
              referralNoteDate: data?.referral_time ?? '',
              patientName: data?.patient_data?.name
                ? data?.patient_data?.name
                : data?.patient_data?.first_name
                ? data?.patient_data?.first_name
                : '',
              patientAge: data?.patient_data?.age ?? '',
              patientGender: data?.patient_data?.gender ?? '',
              referralNotes: data?.referral_notes ?? '',
            },
          },
        }));
        return status?.code;
      }
      set(() => ({
        loading: false,
        summaryState: {
          ...summaryState,
          referralNoteData: {
            doctorName: '',
            speciality: [],
            doctorMobileNumber: '',
            doctorCountryCode: '',
            regNo: '',
            hospitalName: [],
            referralNoteDate: '',
            patientName: '',
            patientAge: '',
            patientGender: '',
            referralNotes: '',
          },
        },
      }));
      setLocalStorage();
      return status?.code;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getPastSummaryData: async (appointment_id) => {
    try {
      const { summaryState, setLocalStorage } = get();
      set({ loading: true });

      if (appointment_id) {
        queryClient.invalidateQueries({
          queryKey: ['pastSummary'],
        });
      }

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [
          'pastSummary',
          summaryState?.appointmentData?.follow_up_appointment,
        ],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/patients/past-summary/view`,
            {
              appointment_id: appointment_id
                ? appointment_id
                : summaryState?.appointmentData?.follow_up_appointment ?? '',
              search: '',
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          summaryState: {
            ...summaryState,
            pastSummaryData: {
              doctorDetails: {
                doctorName:
                  data?.visit_summary?.doctor_profile?.first_name ?? '',
                speciality:
                  Array.isArray(
                    data?.visit_summary?.doctor_profile?.doctor_specialities,
                  ) &&
                  data?.visit_summary?.doctor_profile?.doctor_specialities
                    ?.length > 0
                    ? data?.visit_summary?.doctor_profile?.doctor_specialities
                    : [],
                mobileNo: data?.visit_summary?.doctor_profile?.mobile_no ?? '',
                countryCOde:
                  data?.visit_summary?.doctor_profile?.mobile_no_country_code ??
                  '',
                regNo: data?.visit_summary?.doctor_profile?.register_no ?? '',
                hospital:
                  Array.isArray(
                    data?.visit_summary?.doctor_profile?.working_at,
                  ) &&
                  data?.visit_summary?.doctor_profile?.working_at?.length > 0
                    ? data?.visit_summary?.doctor_profile?.working_at
                    : [],
              },
              appointmentDate: data?.visit_summary?.createdAt ?? '',
              observation: data?.visit_summary?.observation ?? [],
              prescription:
                Array.isArray(data?.prescription_details) &&
                data?.prescription_details?.length > 0
                  ? constructPastSummaryPrescriptionData(
                      data?.prescription_details,
                    )
                  : [],
              labTest: data?.lab_test ?? [],
              advice: data?.visit_summary?.advice ?? [],
              followup: data?.visit_summary?.followup_date ?? '',
              referralNoteData: {
                doctorName:
                  data?.past_referral?.referred_to_doctor_data?.first_name ??
                  '',
                speciality:
                  Array.isArray(
                    data?.past_referral?.referred_to_doctor_data
                      ?.doctor_specialities,
                  ) &&
                  data?.past_referral?.referred_to_doctor_data
                    ?.doctor_specialities?.length > 0
                    ? data?.past_referral?.referred_to_doctor_data
                        ?.doctor_specialities
                    : [],
                doctorMobileNumber:
                  data?.past_referral?.referred_to_doctor_data?.mobile_no ?? '',
                doctorCountryCode:
                  data?.past_referral?.referred_to_doctor_data
                    ?.mobile_no_country_code ?? '',
                regNo:
                  data?.past_referral?.referred_to_doctor_data?.register_no ??
                  '',
                hospitalName:
                  Array.isArray(data?.past_referral?.doctor_data?.working_at) &&
                  data?.past_referral?.doctor_data?.working_at?.length > 0
                    ? data?.past_referral?.doctor_data?.working_at
                    : [],
                referralNoteDate: data?.past_referral?.createdAt ?? '',
                referralNotes: data?.past_referral?.referral_notes ?? '',
              },
            },
          },
        }));
        return status?.code;
      }
      set(() => ({
        loading: false,
        summaryState: {
          ...summaryState,
          pastSummaryData: {
            doctorDetails: {
              doctorName: '',
              speciality: [],
              mobileNo: '',
              countryCOde: '',
              regNo: '',
              hospital: [],
            },
            appointmentDate: '',
            observation: [],
            prescription: [],
            labTest: [],
            advice: [],
            followup: null,
            referralNoteData: {
              doctorName: '',
              speciality: [],
              doctorMobileNumber: '',
              doctorCountryCode: '',
              regNo: '',
              hospitalName: [],
              referralNoteDate: '',
              referralNotes: '',
            },
          },
        },
      }));
      setLocalStorage();
      return status?.code;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  closeVisit: async () => {
    try {
      const { summaryState, updateSummaryPrescriptionData } = get();
      const {
        referToDoctorDetails,
        appointmentData,
        patient_symptoms,
        patient_problems,
        patient_vitals,
      } = summaryState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const parseData = parseJwt(authToken);
      set({ loading: true });
      const payload = {
        // attended_doctor: appointmentData?.doctorId ?? '',
        appointment_id: appointmentData?.id ?? '',
        patient_id: appointmentData?.appointmentProfileId ?? '',
        patient_details: {
          name: appointmentData?.patientName ?? '',
          gender: appointmentData?.patientGender ?? '',
          age: appointmentData?.patientAge ?? null,
        },
        observation: summaryState?.observationData ?? [],
        prescription:
          Array.isArray(summaryState?.prescriptionData) &&
          summaryState?.prescriptionData?.length > 0
            ? constructPrescriptionData(summaryState?.prescriptionData)
            : [],
        prescription_notes: [summaryState?.prescriptionNotesInstruction ?? ''],
        lab_test_details:
          Array.isArray(summaryState?.labTest) &&
          summaryState?.labTest?.length > 0
            ? constructLabTestDetails(summaryState?.labTest)
            : {},
        advice: summaryState?.adviceData ?? [],
        follow_up: {
          followUpNumber: '',
          followUpDuration: '',
          followUpDate: summaryState?.followUp ?? '',
          followUpDateTime: summaryState?.followUp
            ? momentUtc(new Date(summaryState?.followUp))
            : '',
        },
        referral: {
          doctorId: referToDoctorDetails?.doctorId ?? '',
          notes: referToDoctorDetails?.notes ?? '',
          speciality_id: referToDoctorDetails?.doctorSpeciality ?? '',
          referral_doctor_mobile_no:
            referToDoctorDetails?.doctorMobileNumber ?? '',
          referral_doctor_country_code:
            referToDoctorDetails?.doctorCountryCode ?? '',
          referral_doctor_name: referToDoctorDetails?.doctorName ?? '',
        },

        // bookedBy: parseData?.id ?? '',
        assigned_doctor: parseData?.id ?? '',
        // referToDoctorDetails?.doctorId ?? '',
        clientTimeZone: getTimeZoneFnc(),

        patient_symptoms: patient_symptoms ?? [],
        patient_problems: patient_problems ?? [],
        patient_vitals: patient_vitals ?? {},
      };
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['closeVisitAPI'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/visit/complete`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status.code === 200) {
        toast.success(status?.message ?? 'Visit Closed Successfully!!!');
        localStorage.removeItem('summaryState');
        set(() => ({
          loading: false,
        }));
        // To invalidate the cache of performanceStats
        queryClient.invalidateQueries({
          queryKey: ['closeVisitAPI'],
        });
        updateSummaryPrescriptionData([]);
        return status?.code;
      }
      toast.error(status?.message ?? 'Something went wrong please try again!');
      set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      if (error?.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updateAppointmentDate: (start) => {
    const { doctorAppointmentData, setLocalStorage } = get();
    set({
      doctorAppointmentData: {
        ...doctorAppointmentData,
        date: start,
      },
    });
    setLocalStorage();
  },

  setFilter: (value) => {
    const { doctorAppointmentData } = get();
    let patientDetailsCopy = JSON.parse(
      JSON.stringify(doctorAppointmentData?.patientDetails),
    );
    if (value === 'ASC') {
      patientDetailsCopy?.sort(
        (objA, objB) =>
          new Date(objA.appointment_date_time).getTime() -
          new Date(objB.appointment_date_time).getTime(),
      );
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: patientDetailsCopy,
        },
      });
    }

    if (value === 'DESC') {
      set({
        doctorAppointmentData: {
          ...doctorAppointmentData,
          patientDetails: doctorAppointmentData?.patientDetailsIntialState,
        },
      });
    }
  },

  setSeachFilter: (value) => {
    const { doctorAppointmentData } = get();

    let patientDetailsCopy = JSON.parse(
      JSON.stringify(doctorAppointmentData?.patientDetailsIntialState),
    );

    let Arr = patientDetailsCopy?.filter(function (val) {
      return val.name.includes(value);
    });

    set({
      doctorAppointmentData: {
        ...doctorAppointmentData,
        patientDetails: Arr,
      },
    });
  },

  getAppointmentDataById: async (appointment_id) => {
    try {
      const { summaryState, setLocalStorage } = get();
      const { appointmentData } = summaryState;

      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getAppointmentDataById', appointment_id],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/appointments/getAppointment`,
            {
              appointment_id: appointment_id ?? '',
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });
      if (status?.code === '200') {
        set(() => ({
          loading: false,

          summaryState: {
            ...summaryState,
            patient_symptoms: constructPatientSymptomData(
              Array.isArray(data?.[0]?.user_symptoms) &&
                data?.[0]?.user_symptoms?.length > 0
                ? data?.[0]?.user_symptoms
                : [],
              Array.isArray(data?.[0]?.appointment_symptoms) &&
                data?.[0]?.appointment_symptoms?.length > 0
                ? data?.[0]?.appointment_symptoms
                : [],
            ),
            patient_problems: constructPatientProblemData(
              Array.isArray(data?.[0]?.user_health_problems) &&
                data?.[0]?.user_health_problems?.length > 0
                ? data?.[0]?.user_health_problems
                : [],
              Array.isArray(data?.[0]?.appointment_health_problems) &&
                data?.[0]?.appointment_health_problems?.length > 0
                ? data?.[0]?.appointment_health_problems
                : [],
            ),
            patient_vitals:
              data?.[0]?.appointment_members?.[0]?.patient_vital ?? {},
            summaryType:
              data?.[0]?.appointment_type_id === 1
                ? 'followUp'
                : data?.[0]?.appointment_type_id === 2
                ? 'referral'
                : 'booked',
            appointmentData: {
              ...appointmentData,
              appointmentStatus:
                data?.[0]?.master_appointment_status?.appointment_status ?? '',
              appoinmentId: data?.[0]?.appointment_id ?? '',
              id: data?.[0]?.id ?? '',
              appointmentProfileId: data?.[0]?.appointment_profile_id ?? '',
              follow_up_appointment: data?.[0]?.follow_up_appointment ?? '',
              referral_appointment: data?.[0]?.referral_appointment ?? '',
              doctorId: data?.[0]?.assigned_doctor ?? '',
              bookedBy: data?.[0]?.booked_by ?? '',
              isBookedByDoctor:
                data?.[0]?.assigned_doctor === data?.[0]?.booked_by
                  ? true
                  : false,
              patientType:
                data?.[0]?.is_self_booking === true
                  ? 'self'
                  : data?.[0]?.is_self_booking === false &&
                    data?.[0]?.appointment_members?.[0]?.family_member_id
                  ? 'family'
                  : 'others',
              patientId:
                data?.[0]?.is_self_booking === true
                  ? data?.[0]?.appointment_members?.[0]?.profile_id
                  : data?.[0]?.is_self_booking === false &&
                    data?.[0]?.appointment_members?.[0]?.family_member_id
                  ? data?.[0]?.appointment_members?.[0]?.family_member_id
                  : data?.[0]?.appointment_members?.[0]?.id,
              patientName: data?.[0]?.appointment_members?.[0]?.name ?? '',
              patientAge: data?.[0]?.appointment_members?.[0]?.age ?? '',
              patientGender: data?.[0]?.appointment_members?.[0]?.gender ?? '',
              meetUrl: data?.[0]?.meet_url ?? '',
              appointment_date_time: data?.[0]?.appointment_date_time ?? '',
              appointment_time_slot: data?.[0]?.appointment_time_slot ?? '',
              patientMobileNumber:
                data?.[0]?.appointment_members?.[0]?.mobile_no ?? '',
              patientCountryCode:
                data?.[0]?.appointment_members?.[0]?.country_code ?? '',
              healthIssue:
                Array?.isArray(data?.[0]?.health_issues) &&
                data?.[0]?.health_issues
                  ? data?.[0]?.health_issues
                  : [],
              symptom:
                Array?.isArray(data?.[0]?.appointment_symptoms) &&
                data?.[0]?.appointment_symptoms
                  ? constructSymptomData(data?.[0]?.appointment_symptoms)
                  : [],
              user_symptoms: data?.[0]?.user_symptoms ?? '',
              user_health_problems: data?.[0]?.user_health_problems ?? '',
              problem:
                Array?.isArray(data?.[0]?.appointment_health_problems) &&
                data?.[0]?.appointment_health_problems
                  ? constructHealthProblemData(
                      data?.[0]?.appointment_health_problems,
                    )
                  : [],
              reportCount:
                Array.isArray(data?.[0]?.appointment_uploads) &&
                data?.[0]?.appointment_uploads?.length > 0
                  ? data?.[0]?.appointment_uploads?.length
                  : 0,
              vitals: {
                height:
                  data?.[0]?.appointment_members?.[0]?.patient_vital
                    ?.height_in_cm ?? '',
                weight:
                  data?.[0]?.appointment_members?.[0]?.patient_vital
                    ?.weight_in_cm ?? '',
                temprature:
                  data?.[0]?.appointment_members?.[0]?.patient_vital
                    ?.temp_in_f ?? '',
                pulse:
                  data?.[0]?.appointment_members?.[0]?.patient_vital
                    ?.puls_bpm ?? '',
                bloodPressure:
                  data?.[0]?.appointment_members?.[0]?.patient_vital?.bp_sys &&
                  data?.[0]?.appointment_members?.[0]?.patient_vital?.bp_dia
                    ? `${data?.[0]?.appointment_members?.[0]?.patient_vital?.bp_sys} / ${data?.[0]?.appointment_members?.[0]?.patient_vital?.bp_dia}`
                    : '',
                o2:
                  data?.[0]?.appointment_members?.[0]?.patient_vital
                    ?.o2_level ?? '',
              },
            },
          },
        }));
        setLocalStorage();
        return status?.code;
      }
      set(() => ({
        loading: false,
        summaryState: {
          ...summaryState,
          patient_symptoms: [],
          patient_problems: [],
          patient_vitals: {},
          appointmentData: {
            ...appointmentData,
            id: '',
            appointmentProfileId: '',
            doctorId: '',
            patientType: '',
            patientId: '',
            patientName: '',
            patientAge: '',
            patientGender: '',
            meetUrl: '',
            patientMobileNumber: '',
            healthIssue: [],
            symptom: [],
            problem: [],
            isBookedByDoctor: null,
            reportCount: 0,
            vitals: {
              height: '',
              weight: '',
              temprature: '',
              pulse: '',
              bloodPressure: '',
              o2: '',
            },
          },
        },
      }));
      setLocalStorage();
      return status?.code;
    } catch (error) {
      set({ loading: false });
      if (error.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  addLabtest: (e, option) => {
    const { labTestDetails, setLocalStorage } = get();
    const { labTestArray } = labTestDetails;
    let labTestArrayCopy = JSON.parse(JSON.stringify(labTestArray));
    if (labTestArrayCopy.length > 0) {
      const labTestArrayFilter = labTestArrayCopy.filter(
        (v) => v?.value === option?.value,
      );
      if (!labTestArrayFilter?.length > 0) {
        if (option.value === option.labelShow) {
          let obj = {
            is_new: true,
            label: option.labelShow,
          };
          labTestArrayCopy.push(obj);
        } else {
          if (option.value !== option.labelShow) {
            let obj = {
              is_new: false,
              value: option.value,
              label: option.label,
            };
            labTestArrayCopy.push(obj);
          }
        }
        // labTestArrayCopy.push(option);
      } else {
        const labTestArrayFilterData = labTestArrayCopy.filter(
          (v) => v?.value !== option?.value,
        );
        labTestArrayCopy = labTestArrayFilterData;
      }
    } else {
      if (option.value === option.labelShow) {
        let obj = {
          is_new: true,
          label: option.labelShow,
        };
        labTestArrayCopy.push(obj);
      } else {
        if (option.value !== option.labelShow) {
          let obj = {
            is_new: false,
            value: option.value,
            label: option.label,
          };
          labTestArrayCopy.push(obj);
        }
      }
    }
    set({
      labTestDetails: {
        ...labTestDetails,
        labTestArray: labTestArrayCopy,
      },
    });
    setLocalStorage();
  },
  // DELETE DATA
  deleteLabData: (i) => {
    const { labTestDetails, setLocalStorage } = get();
    const { labTestArray } = labTestDetails;
    const copyData = JSON.parse(JSON.stringify(labTestArray));
    copyData.splice(i, 1);
    set({
      labTestDetails: {
        ...labTestDetails,
        labTestArray: copyData,
      },
    });
    setLocalStorage();
  },
  // CLEAR PAGE DATA
  clearPageData: () => {
    const { labTestDetails, setLocalStorage } = get();
    set({
      labTestDetails: {
        ...labTestDetails,
        labTestArray: [],
      },
    });
    setLocalStorage();
  },

  clearLabTestDetailsState: () => {
    const { setLocalStorage } = get();
    set({
      labTestDetails: {
        labTestArray: [],
        note: '',
        labTestDate: '',
      },
    });
    setLocalStorage();
  },
  updateSummaryState: (key, value) => {
    const { summaryState, setLocalStorage } = get();

    set({
      summaryState: {
        ...summaryState,
        [key]: value,
      },
    });

    setLocalStorage();
  },
  updateSummaryPrescriptionData: (value) => {
    const { summaryState, setLocalStorage } = get();
    set({
      summaryState: {
        ...summaryState,
        prescriptionData: value,
      },
    });
    setLocalStorage();
    return true;
  },

  sentReferralDoctor: async () => {
    try {
      const { summaryState, setLocalStorage } = get();
      const { appointmentData, referToDoctorModelDetails } = summaryState;
      const {
        doctorSpeciality,
        doctorName,
        doctorId,
        doctorMobileNumber,
        doctorCountryCode,
        notes,
      } = referToDoctorModelDetails;

      const wordLenth = notes.split(' ');
      let result;

      for (const val of wordLenth) {
        if (val.length > 25) {
          result = false;
        } else {
          result = true;
        }
      }
      if (result) {
        const authToken = localStorage.getItem(localStorageKeys.authToken);
        const parseData = parseJwt(authToken);
        set({ loading: true });
        const payload = {
          patient_profile_id: appointmentData ? appointmentData?.patientId : '',
          appointment_id: appointmentData?.id ?? '',
          referral_doctor_mobile_no: doctorMobileNumber ?? '',
          referral_doctor_country_code:
            doctorMobileNumber && doctorCountryCode ? doctorCountryCode : '',
          referral_doctor_name: doctorName ?? '',
          speciality_id: doctorSpeciality ?? '',
          referred_by: parseData?.profile?.id ?? '',
          referred_to: doctorId ?? '',
          referral_notes: notes ?? '',
        };
        // const wordLenth = payload?.referral_notes.split();
        // if (wordLenth[0].length < 30) {
        const { data, status } = await queryClient.fetchQuery({
          queryKey: ['doctorSendReferral'],
          queryFn: async () => {
            const { data } = await httpRequest(
              'post',
              `${envConfig.api_url}/doctor/send-referral`,
              { ...payload },
              true,
            );
            return data;
          },
          staleTime: 120000,
        });

        if (status?.code === 200) {
          const { viewReferral } = get();
          const viewReferralResponse = await viewReferral(
            data?.referral_id ?? '',
          );
          const { summaryState } = get();

          set((state) => ({
            loading: false,
            summaryState: {
              ...state.summaryState,
              referToDoctorDetails: {
                ...state.summaryState?.referToDoctorDetails,
                doctorSpeciality:
                  Array.isArray(
                    viewReferralResponse?.referred_to_doctor
                      ?.doctor_specialities,
                  ) &&
                  viewReferralResponse?.referred_to_doctor?.doctor_specialities
                    ?.length > 0
                    ? constructSpecialityData(
                        viewReferralResponse?.referred_to_doctor
                          ?.doctor_specialities,
                      )
                    : [],
                doctorName: viewReferralResponse?.referred_to_doctor?.first_name
                  ? viewReferralResponse?.referred_to_doctor?.first_name
                  : viewReferralResponse?.referred_to_doctor?.name
                  ? viewReferralResponse?.referred_to_doctor?.name
                  : '',
                doctorId: viewReferralResponse?.referred_to_doctor?.id ?? '',
                doctorMobileNumber:
                  viewReferralResponse?.referred_to_doctor?.mobile_no ?? '',
                doctorCountryCode: viewReferralResponse?.referred_to_doctor
                  ?.country_code
                  ? viewReferralResponse?.referred_to_doctor?.country_code
                  : viewReferralResponse?.referred_to_doctor
                      ?.mobile_no_country_code
                  ? viewReferralResponse?.referred_to_doctor
                      ?.mobile_no_country_code
                  : '',
                notes: viewReferralResponse?.referral_notes ?? '',
              },
            },
          }));
        }

        // set(() => ({
        //   loading: false,
        //   summaryState: {
        //     ...summaryState,
        //     referToDoctorDetails: {
        //       doctorSpeciality: '',
        //       doctorName: '',
        //       doctorId: '',
        //       doctorMobileNumber: '',
        //       doctorCountryCode: '+91',
        //       notes: '',
        //     },
        //   },
        // }));
        setLocalStorage();
        return status;
      }
      toast.error('Please check space between the words!');
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  viewReferral: async (referralId) => {
    try {
      set({ loading: true });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['doctorViewReferral'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/view-referral`,
            { referral_id: referralId, appointment_id: '' },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
        }));
        return data;
      }
      set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  initialReferralModelData: () => {
    const { summaryState, setLocalStorage } = get();
    set(() => ({
      summaryState: {
        ...summaryState,
        referToDoctorModelDetails: {
          doctorSpeciality: '',
          doctorName: '',
          doctorId: '',
          doctorMobileNumber: '',
          doctorCountryCode: '+91',
          notes: '',
        },
        isNewDoctor: false,
      },
    }));
    setLocalStorage();
  },
  updatehealthData: (key, value) => {
    const { summaryState, setLocalStorage } = get();
    const { appointmentData } = summaryState;
    set(() => ({
      summaryState: {
        ...summaryState,
        appointmentData: {
          ...appointmentData,
          [key]: value,
        },
      },
    }));
    setLocalStorage();
  },
  setUserHealthProblems: async () => {
    try {
      const { summaryState, setLocalStorage } = get();
      const { appointmentData } = summaryState;

      set({ loading: true });

      const payload = {
        doctor_id: appointmentData?.doctorId ?? '',
        appointment_id: appointmentData?.id ?? '',
        symptoms: [],
        health_problems: [],
        health_issues: appointmentData?.healthIssue ?? '',
        user_symptoms: appointmentData?.symptom ?? '',
        user_health_problems: appointmentData?.problem ?? '',
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['patient-problems', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/appointment/patient-problems`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      setLocalStorage();
      return status?.code;
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
}));
