/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import { AppBar, HealthCirclesLogo } from '@atoms';
import {
  FooterPatientIcon,
  FooterProfileIcon,
  FooterShareIcon,
  HealthCircleIcon,
} from '@atoms/icons';
import { useViewPort } from '@hc/store';
import { localStorageKeys } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { doctorlayout_style } from './style';

export function Layout(props) {
  const {
    bodyStyle,
    className = '',
    rootStyle,
    appBarStyle,
    children,
    menuItems,
    isDoctorVerfied,
    ...rest
  } = props;

  const { isMobilePort, setValue, homeTabIndex } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
    setValue: state.setValue,
    homeTabIndex: state.homeTabIndex,
  }));

  const navItems = [
    {
      item: 'Appointments',
      icon: 'appointments',
    },
    {
      item: 'Referrals',
      icon: 'referrals',
    },
    {
      item: 'Patients',
      icon: 'patients',
    },
    {
      item: 'Profile',
      icon: 'userName',
    },
  ];

  const navIcons = {
    appointments: HealthCircleIcon,
    referrals: FooterShareIcon,
    patients: FooterPatientIcon,
    userName: FooterProfileIcon,
  };

  const onClick = (index) => {
    setValue('homeTabIndex', index.toString());
  };
  useEffect(() => {
    const homeTab = sessionStorage.getItem(localStorageKeys?.doctorResPage);
    setValue('homeTabIndex', homeTab === null ? '0' : homeTab);
  }, []);
  return (
    <Box
      sx={{ ...doctorlayout_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ ...doctorlayout_style.appBarSx, ...appBarStyle }}>
        <AppBar
          Organizationlogo={<HealthCirclesLogo />}
          organizationName="HealthCircles"
          OrganizationNotificationPopupTitle="Notification"
          menuItems={menuItems}
          anchorOriginStyle={doctorlayout_style.anchorStyleSx}
          transformOriginStyle={{
            ...(!isMobilePort && { vertical: 'top', horizontal: 'right' }),
          }}
          menuStyle={doctorlayout_style.menuSx}
          menuItemStyle={doctorlayout_style.menuItemSx}
          menuIconStyle={doctorlayout_style.drawerSx}
          version={
            localStorage.getItem(localStorageKeys.doctorVersion) ?? '0.0.0'
          }
          navStyle={doctorlayout_style.navSx}
        />
      </Box>
      <Box
        sx={{
          ...(isMobilePort ? ' ' : doctorlayout_style.bodySx),
          ...bodyStyle,
        }}
      >
        {children}
      </Box>

      {/* the fault in our stars */}
      {isDoctorVerfied && (
        <Box>
          {isMobilePort && (
            <Box sx={{ ...doctorlayout_style.footerNavBarSx }}>
              {navItems.map((navItem, i) => {
                const Icon = navIcons[navItem.icon];
                return (
                  <Box
                    key={i}
                    sx={doctorlayout_style.navItemSx}
                    onClick={() => {
                      onClick(i);
                    }}
                    id={navItem.item}
                  >
                    <Icon
                      rootStyle={{
                        color: homeTabIndex == i ? '#007965' : 'text.secondary',
                      }}
                    />
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        ...doctorlayout_style.navItemTextSx,
                        color: homeTabIndex == i ? '#007965' : 'text.secondary',
                      }}
                    >
                      {navItem.item}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

Layout.propTypes = {
  menuItems: PropTypes.array,
};
