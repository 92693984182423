import { DeleteIcon } from '@atoms/icons/iconSet1';
import { useRxGroup } from '@hc/store/doctor/rxGroup';
import { calculateDoseQuantity } from '@hc/utils';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-hot-toast';
import { BiChevronRight } from 'react-icons/bi';
import { PrescriptionDeleteModal } from '../../prescriptionDeleteModal';
import { PrescriptionDoseModal } from '../../prescriptionDoseModal';
import { PrescriptionDurationModal } from '../../prescriptionDurationModal';
import { PrescriptionTimeFrequencyModal } from '../../prescriptionTimefrequencyModal';
import { prescriptionListCard_style } from './style';
import { MobilePrescriptionCard } from '../../../mobilePrescriptionCard';

function PrescriptionListCard(props) {
  const {
    className = '',
    rootStyle = {},
    DrugName,
    Dose,
    Frequency,
    Duration,
    isMobileResponsive,
    ...rest
  } = props;

  const {
    rxGroupState,
    deleteDrugData,
    changeDoseDataFnc,
    changeTimeFrequencyWhenDataFnc,
    changeDurationQuantityDataFnc,
  } = useRxGroup((state) => ({
    rxGroupState: state.rxGroupState,
    deleteDrugData: state.deleteDrugData,
    changeDoseDataFnc: state.changeDoseDataFnc,
    changeTimeFrequencyWhenDataFnc: state.changeTimeFrequencyWhenDataFnc,
    changeDurationQuantityDataFnc: state.changeDurationQuantityDataFnc,
  }));
  const { drugRxGroupData } = rxGroupState;
  const [DoseModalOpen, setDoseModalOpen] = React.useState(false);
  const [TimeFrequencyModal, setTimeFrequencyModal] = React.useState(false);
  const [DurationModal, setDurationModal] = React.useState(false);
  const [DeleteModal, setDeleteModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({
    drugName: '',
    index: null,
  });
  const [doseData, setDoseData] = React.useState({
    morningDose: 0,
    afterNoonDose: 0,
    nightDose: 0,
    index: null,
  });

  const [categortIdState, setCategortIdState] = React.useState(1);

  const [timeFrequencyWhenData, setTimeFrequencyWhenData] = React.useState({
    time: {
      value: 0,
      label: '',
    },
    frequency: {
      value: 0,
      label: '',
    },
    when: {
      value: 0,
      label: '',
    },
    index: null,
  });
  const [durationQuantityData, setDurationQuantityData] = React.useState({
    duration: 0,
    customDuration: 0,
    durationMode: {
      value: 0,
      label: '',
    },
    quantity: 0,
    index: null,
  });

  // -------------------------------------------------
  // DOSE MODEL OPEN FUNCTION
  const DoseModalOpenfunc = (val, i) => {
    setDoseData({
      morningDose: val?.morningDose ?? 0,
      afterNoonDose: val?.afterNoonDose ?? 0,
      nightDose: val?.nightDose ?? 0,
      index: i,
    });
    setDoseModalOpen(true);
  };
  // DOSE MODEL CLOSE FUNCTION
  const DoseModalClosefunc = () => {
    setDoseModalOpen(false);
    setDoseData({
      morningDose: 0,
      afterNoonDose: 0,
      nightDose: 0,
      index: null,
    });
  };
  // MODEL DOSE DATA CHANGE FUNCTION
  const doseChange = (key, value) => {
    setDoseData({
      ...doseData,
      [key]: value,
    });
  };
  // SAVE DOSE DATA IN STORE
  const handleDoseSave = async () => {
    await changeDoseDataFnc(doseData);
    DoseModalClosefunc();
  };
  // -------------------------------------------------
  // TIME_FREQUECNY_WHEN MODEL OPEN FUNCTION
  const FrequencyModelOpenfunc = (val, i) => {
    setTimeFrequencyWhenData({
      time: {
        value: val?.time?.value ?? 0,
        label: val?.time?.label ?? '',
      },
      frequency: {
        value: val?.frequency?.value ?? 0,
        label: val?.frequency?.label ?? '',
      },
      when: {
        value: val?.when?.value ?? 0,
        label: val?.when?.label ?? '',
      },
      index: i,
    });
    setTimeFrequencyModal(true);
  };
  // TIME_FREQUECNY_WHEN MODEL CLOSE FUNCTION
  const TimeFrequencyModalCloseFunc = () => {
    setTimeFrequencyModal(false);
    setTimeFrequencyWhenData({
      time: {
        value: 0,
        label: '',
      },
      frequency: {
        value: 0,
        label: '',
      },
      when: {
        value: 0,
        label: '',
      },
      index: null,
    });
  };
  // MODEL TIME_FREQUECNY_WHEN DATA CHANGE FUNCTION
  const timeFrequencyWhenChange = (key, value, label) => {
    setTimeFrequencyWhenData({
      ...timeFrequencyWhenData,
      [key]: {
        label,
        value,
      },
    });
  };
  // SAVE TIME_FREQUECNY_WHEN DATA IN STORE
  const handleTimeFrequencyWhenSave = async () => {
    await changeTimeFrequencyWhenDataFnc(timeFrequencyWhenData);
    TimeFrequencyModalCloseFunc();
  };
  // -------------------------------------------------
  // DURATION_QUANTITY MODEL OPEN FUNCTION
  const DurationModalOpenFunc = (val, i) => {
    setDurationQuantityData({
      duration: val?.duration <= 6 ? val?.duration : 0,
      customDuration: val?.duration >= 7 ? val?.duration : 0,
      durationMode: {
        value: val?.durationMode?.value ?? 0,
        label: val?.durationMode?.label ?? '',
      },
      quantity: val?.quantity ?? 0,
      index: i,
    });
    setCategortIdState(val?.category?.value ?? 1);
    setDurationModal(true);
  };
  // DURATION_QUANTITY MODEL CLOSE FUNCTION
  const DurationModalCloseFunc = () => {
    setCategortIdState(1);
    setDurationModal(false);
    setDurationQuantityData({
      duration: 0,
      customDuration: 0,
      durationMode: {
        value: 0,
        label: '',
      },
      quantity: 0,
      index: null,
    });
  };
  // MODEL DURATION_QUANTITY DATA CHANGE FUNCTION
  const durationQuantityChange = (key, value, label) => {
    if (key === 'duration') {
      // duration = 0,
      // durationModeId = 1,
      // frequency = 1,
      // doseCumulative
      if (categortIdState === 1) {
        const quantityH = calculateDoseQuantity(
          value,
          durationQuantityData?.durationMode?.value,
          drugRxGroupData[durationQuantityData?.index]?.frequency?.value,
          drugRxGroupData[durationQuantityData?.index]?.morningDose +
            drugRxGroupData[durationQuantityData?.index]?.afterNoonDose +
            drugRxGroupData[durationQuantityData?.index]?.nightDose,
        );
        setDurationQuantityData({
          ...durationQuantityData,
          customDuration: 0,
          quantity: quantityH,
          [key]: value,
        });
      } else {
        setDurationQuantityData({
          ...durationQuantityData,
          customDuration: 0,
          [key]: value,
        });
      }
    } else if (key === 'customDuration_decrease') {
      if (categortIdState === 1) {
        const quantityH = calculateDoseQuantity(
          durationQuantityData?.customDuration - 1,
          durationQuantityData?.durationMode?.value,
          drugRxGroupData[durationQuantityData?.index]?.frequency?.value,
          drugRxGroupData[durationQuantityData?.index]?.morningDose +
            drugRxGroupData[durationQuantityData?.index]?.afterNoonDose +
            drugRxGroupData[durationQuantityData?.index]?.nightDose,
        );
        setDurationQuantityData({
          ...durationQuantityData,
          duration: 0,
          quantity: quantityH,
          customDuration: durationQuantityData?.customDuration - 1,
        });
      } else {
        setDurationQuantityData({
          ...durationQuantityData,
          duration: 0,
          customDuration: durationQuantityData?.customDuration - 1,
        });
      }
    } else if (key === 'customDuration_increase') {
      if (categortIdState === 1) {
        const quantityH = calculateDoseQuantity(
          durationQuantityData?.customDuration + 1,
          durationQuantityData?.durationMode?.value,
          drugRxGroupData[durationQuantityData?.index]?.frequency?.value,
          drugRxGroupData[durationQuantityData?.index]?.morningDose +
            drugRxGroupData[durationQuantityData?.index]?.afterNoonDose +
            drugRxGroupData[durationQuantityData?.index]?.nightDose,
        );
        setDurationQuantityData({
          ...durationQuantityData,
          duration: 0,
          quantity: quantityH,
          customDuration: durationQuantityData?.customDuration + 1,
        });
      } else {
        setDurationQuantityData({
          ...durationQuantityData,
          duration: 0,
          customDuration: durationQuantityData?.customDuration + 1,
        });
      }
    } else if (key === 'durationMode') {
      if (categortIdState === 1) {
        const quantityH = calculateDoseQuantity(
          durationQuantityData?.duration > 0
            ? durationQuantityData?.duration
            : durationQuantityData?.customDuration > 0
            ? durationQuantityData?.customDuration
            : 0,
          value,
          drugRxGroupData[durationQuantityData?.index]?.frequency?.value,
          drugRxGroupData[durationQuantityData?.index]?.morningDose +
            drugRxGroupData[durationQuantityData?.index]?.afterNoonDose +
            drugRxGroupData[durationQuantityData?.index]?.nightDose,
        );
        setDurationQuantityData({
          ...durationQuantityData,
          quantity: quantityH,
          [key]: {
            label,
            value,
          },
        });
      } else {
        setDurationQuantityData({
          ...durationQuantityData,
          [key]: {
            label,
            value,
          },
        });
      }
    }
  };
  // SAVE DURATION_QUANTITY DATA IN STORE

  const handleDurationQuantitySave = async () => {
    if (
      durationQuantityData?.duration > 0 ||
      durationQuantityData?.customDuration > 0
    ) {
      if (durationQuantityData?.durationMode?.label?.length > 0) {
        if (durationQuantityData?.quantity > 0) {
          await changeDurationQuantityDataFnc(durationQuantityData);
          DurationModalCloseFunc();
        } else {
          toast.error('Please fill the drug quantity');
        }
      } else {
        toast.error('Please fill the drug duration mode');
      }
    } else {
      toast.error('Please fill the drug duration');
    }
  };

  // -------------------------------------------------
  // DELETE MODEL OPEN FUNCTION
  const DeleteModalOpenFun = (drugName, i) => {
    setDeleteData({
      drugName,
      index: i,
    });
    setDeleteModal(true);
  };
  // DELETE MODEL CLOSE FUNCTION
  const DeleteModalCloseFun = () => {
    setDeleteModal(false);
    setDeleteData({
      drugName: '',
      index: null,
    });
  };
  // DELETE DOSE
  const handleDelete = async () => {
    await deleteDrugData(deleteData?.index);
    DeleteModalCloseFun();
  };

  return (
    <>
      {!isMobileResponsive &&
        Array.isArray(drugRxGroupData) &&
        drugRxGroupData?.length > 0 &&
        drugRxGroupData.map(
          (val, i) =>
            val?.isDeleted === false && (
              <Box
                id={`customize${i + 1}`}
                key={i}
                sx={{
                  ...prescriptionListCard_style.rootSx,
                  ...rootStyle,
                  pb: 1,
                }}
                className={`${className}`}
                {...rest}
              >
                <Grid container sx={prescriptionListCard_style.containerSx}>
                  <Grid item xs={3}>
                    {' '}
                    <Typography sx={prescriptionListCard_style.DrugnameSx}>
                      {val?.drugName ?? ''}
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      id="doseCustomize"
                      sx={{ height: '20px', borderRadius: '8px' }}
                      onClick={() => DoseModalOpenfunc(val, i)}
                    >
                      {val?.morningDose === 0 &&
                      val?.afterNoonDose === 0 &&
                      val?.nightDose === 0 ? (
                        <Typography
                          sx={prescriptionListCard_style.customclickSx}
                        >
                          + Customize
                        </Typography>
                      ) : (
                        <Typography sx={prescriptionListCard_style.typoSx}>
                          {`${val?.morningDose}-${val?.afterNoonDose}-${val?.nightDose}`}
                          {/* '1-0-1' */}
                        </Typography>
                      )}

                      <BiChevronRight
                        color="#007965"
                        width="5px"
                        height="9px"
                      />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3}>
                    <IconButton
                      id="FrequencyCustom"
                      sx={prescriptionListCard_style.listDataAlign}
                      onClick={() => FrequencyModelOpenfunc(val, i)}
                    >
                      <Typography sx={prescriptionListCard_style.typoSx}>
                        {`${val?.time?.label}, ${val?.frequency?.label}, ${val?.when?.label}`}{' '}
                      </Typography>

                      <BiChevronRight
                        color="#007965"
                        width="5px"
                        height="9px"
                      />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3}>
                    <IconButton
                      sx={prescriptionListCard_style.listDataAlign}
                      onClick={() => DurationModalOpenFunc(val, i)}
                    >
                      {val?.duration ? (
                        <Typography sx={prescriptionListCard_style.typoSx}>
                          {`${val?.duration} ${val?.durationMode?.label}, ${val?.quantity} nos`}
                        </Typography>
                      ) : (
                        <Typography
                          sx={prescriptionListCard_style.customclickSx}
                        >
                          + Customize
                        </Typography>
                      )}

                      <BiChevronRight
                        color="#007965"
                        width="5px"
                        height="9px"
                      />
                    </IconButton>
                  </Grid>

                  <Grid item xs={1}>
                    <Typography sx={prescriptionListCard_style.typoSx}>
                      <IconButton
                        onClick={() => DeleteModalOpenFun(val?.drugName, i)}
                        aria-label="delete"
                        color="secondary"
                        sx={{ backgroundColor: '#FEEDEE ', p: 1.4 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ),
        )}

      {isMobileResponsive &&
        Array.isArray(drugRxGroupData) &&
        drugRxGroupData?.length > 0 &&
        drugRxGroupData.map(
          (val, i) =>
            val?.isDeleted === false && (
              <Box key={i}>
                <MobilePrescriptionCard
                  id={i}
                  drugName={val?.drugName ?? ''}
                  dose={`${val?.morningDose}-${val?.afterNoonDose}-${val?.nightDose}`}
                  time={`${val?.time?.label}, ${val?.frequency?.label}, ${val?.when?.label}`}
                  duration={`${val?.duration} ${val?.durationMode?.label} `}
                  quality={val?.quantity}
                  deleteDrug={DeleteModalOpenFun}
                  data={val}
                  DoseModalOpenfunc={DoseModalOpenfunc}
                  FrequencyModelOpenfunc={FrequencyModelOpenfunc}
                  DurationModalOpenFunc={DurationModalOpenFunc}
                />
              </Box>
            ),
        )}

      <PrescriptionDoseModal
        isMobileResponsive={isMobileResponsive}
        isModalOpen={DoseModalOpen}
        handleClose={DoseModalClosefunc}
        doseData={doseData}
        doseChange={doseChange}
        handleDoseSave={handleDoseSave}
      />
      <PrescriptionTimeFrequencyModal
        isMobileResponsive={isMobileResponsive}
        isModalOpen={TimeFrequencyModal}
        handleClose={TimeFrequencyModalCloseFunc}
        timeFrequencyWhenData={timeFrequencyWhenData}
        timeFrequencyWhenChange={timeFrequencyWhenChange}
        handleTimeFrequencyWhenSave={handleTimeFrequencyWhenSave}
      />
      <PrescriptionDurationModal
        isMobileResponsive={isMobileResponsive}
        isModalOpen={DurationModal}
        handleClose={DurationModalCloseFunc}
        durationQuantityData={durationQuantityData}
        durationQuantityChange={durationQuantityChange}
        handleDurationQuantitySave={handleDurationQuantitySave}
      />
      <PrescriptionDeleteModal
        isMobileResponsive={isMobileResponsive}
        isModalOpen={DeleteModal}
        handleClose={DeleteModalCloseFun}
        handleDelete={handleDelete}
        drugName={deleteData?.drugName}
      />
    </>
  );
}

PrescriptionListCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  DrugName: PropTypes.any,
  Dose: PropTypes.any,
  Frequency: PropTypes.any,
  Duration: PropTypes.any,
  isMobileResponsive: PropTypes.any,
};

export { PrescriptionListCard };
