import { getReferralDateFormat } from '@hc/dayjs';
import { useSummary } from '@hc/store';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CalenderIcon } from '../../../atoms';
import { pastReferNote_style } from './style';

function PastReferNote(props) {
  const {
    pastSummary = false,
    className = '',
    referralShown = false,
    rootStyle = {},
    ShowNotes = true,
    summaryModelView = false,
    ...rest
  } = props;

  const { summaryState } = useSummary((state) => ({
    summaryState: state.summaryState,
  }));

  const { pastSummaryData, referToDoctorDetails } = summaryState;
  const { doctorDetails, appointmentDate, referralNoteData } = pastSummaryData;
  const { doctorName, speciality, mobileNo, countryCOde, hospital } =
    doctorDetails;

  return (
    <Box
      sx={{
        ...pastReferNote_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {!summaryModelView && !referralShown && !pastSummary && (
        <Box sx={pastReferNote_style.pastReferRootSx}>
          <Box sx={pastReferNote_style.cardSx}>
            <Box display="flex" alignItems="center">
              <Typography sx={pastReferNote_style.commonTextSx}>
                Referred to
              </Typography>
              <Divider
                orientation="vertical"
                sx={pastReferNote_style.dividerSx}
              />
              <Typography sx={pastReferNote_style.commonTextSx}>
                Dr. Prasath &nbsp;
              </Typography>
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                • &nbsp; Cardiologist
              </Typography>
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                &nbsp; • (318) 420-9835
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <CalenderIcon />
              &nbsp;
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                22-08-2022
              </Typography>
            </Box>
          </Box>
          {ShowNotes && (
            <>
              <Divider sx={pastReferNote_style.divideDottedSx} />
              <Typography sx={pastReferNote_style.commonTextSx}>
                Notes
              </Typography>
              <Typography sx={pastReferNote_style.issuesTextSx}>
                The issues is little compolicated for me to handle so requiring
                your
              </Typography>
            </>
          )}
        </Box>
      )}
      {pastSummary && referralNoteData?.doctorName && (
        <Box sx={pastReferNote_style.pastReferRootSx}>
          <Box sx={pastReferNote_style.cardSx}>
            <Box display="flex" alignItems="center">
              {/* NEW CODE */}
              <Typography sx={pastReferNote_style.commonTextSx}>
                {`Dr. ${referralNoteData?.doctorName} `}&nbsp;
              </Typography>
              <Typography
                sx={{
                  color: 'text.hint',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {Array.isArray(referralNoteData?.speciality) &&
                  referralNoteData?.speciality?.length > 0 &&
                  referralNoteData?.speciality.map((val, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: 'text.hint',
                        fontSize: '12px!important',
                        fontWeight: 'medium',
                      }}
                    >
                      • &nbsp;{val?.details?.speciality ?? ''}&nbsp;
                    </Typography>
                  ))}
              </Typography>
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                &nbsp; •
                {`(${referralNoteData?.doctorCountryCode}) ${referralNoteData?.doctorMobileNumber}`}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <CalenderIcon />
              &nbsp;
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                {getReferralDateFormat(
                  referralNoteData?.referralNoteDate,
                  'DD-MM-YYYY'
                )}
              </Typography>
            </Box>
          </Box>
          <Divider sx={pastReferNote_style.divideDottedSx} />
          <Box>
            <Box
              display="flex"
              alignItems="center"
              sx={pastReferNote_style.cardSxx}
            >
              <Typography sx={pastReferNote_style.commonTextSx}>
                Notes
              </Typography>
              <Typography sx={pastReferNote_style.issuesTextSx}>
                {referralNoteData?.referralNotes}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {referralShown && !pastSummary && (
        <Box sx={pastReferNote_style.boxCardSx}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              {`Dr. ${doctorName}`}&nbsp;
            </Typography>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '12px',
                fontWeight: 600,
              }}
            >
              {/* Referral Note */}
              {`On ${getReferralDateFormat(appointmentDate, 'DD MMM, YYYY')}`}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" mt={1}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                sx={{
                  color: 'text.hint',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {Array.isArray(speciality) &&
                  speciality?.length > 0 &&
                  speciality.map((val, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: 'text.hint',
                        fontSize: '12px!important',
                        fontWeight: 'medium',
                      }}
                    >
                      • &nbsp;{val?.master_speciality?.speciality ?? ''}&nbsp;
                    </Typography>
                  ))}
              </Typography>

              <Typography
                sx={{
                  color: 'text.hint',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {`${countryCOde}   ${mobileNo}`} &nbsp;
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              color: 'text.hint',
              fontSize: '12px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}
          >
            {Array.isArray(hospital) &&
              hospital?.length > 0 &&
              hospital.map((val, index) => (
                <>
                  {index > 0 ? (
                    <span
                      key={index}
                      style={{
                        // backgroundColor: 'PrimaryTints.A100',
                        padding: '4px 0px',
                        borderRadius: '4px',
                        color: '#868484',
                        fontSize: '12px',
                        // marginLeft: '6px',
                        fontWeight: 600,
                      }}
                    >
                      {', '}{' '}
                    </span>
                  ) : null}
                  <Typography
                    key={index}
                    sx={{
                      color: 'text.hint',
                      fontSize: '12px!important',
                      fontWeight: 'medium',
                    }}
                  >
                    &nbsp;{val ?? ''}&nbsp;
                  </Typography>
                </>
              ))}
            {/* Hospital Name */}
          </Typography>
        </Box>
      )}
      {summaryModelView && (
        <Box sx={pastReferNote_style.pastReferRootSxx}>
          <Box sx={pastReferNote_style.cardSx}>
            <Box display="flex" alignItems="center">
              {/* NEW CODE */}
              <Typography sx={pastReferNote_style.commonTextSx}>
                {`Dr. ${referToDoctorDetails?.doctorName}`} &nbsp;
              </Typography>
              <Typography
                sx={{
                  color: 'text.hint',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {Array.isArray(referToDoctorDetails?.doctorSpeciality) &&
                  referToDoctorDetails?.doctorSpeciality?.length > 0 &&
                  referToDoctorDetails?.doctorSpeciality.map((val, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: 'text.hint',
                        fontSize: '12px!important',
                        fontWeight: 'medium',
                      }}
                    >
                      • &nbsp;{val?.label ?? ''}&nbsp;
                    </Typography>
                  ))}
              </Typography>
              <Typography sx={pastReferNote_style.subCommonTextSx}>
                &nbsp; •{' '}
                {`(${referToDoctorDetails?.doctorCountryCode}) ${referToDoctorDetails?.doctorMobileNumber}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

PastReferNote.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  referralShown: PropTypes.bool,
  pastSummary: PropTypes.bool,
  summaryModelView: PropTypes.bool,
  ShowNotes: PropTypes.bool,
};

export { PastReferNote };
