export const viewProfile_style = {
  rootSx: {
    height: '100%',
    pb: 3,
  },
  totalProfileSx: {
    display: 'flex',
    justifyContent: 'center',
  },
  cameraSx: {
    backgroundColor: 'primary.main',
    borderRadius: '50px',
    border: '4px solid',
    borderColor: '#EBFAF7',
    // '&:hover': {
    //   backgroundColor: 'primary.main',
    //   borderColor: 'common.white',
    // },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60px',
    width: '60px',
  },

  responsivecameraSx: {
    backgroundColor: 'primary.main',
    borderRadius: '50px',
    border: '4px solid',
    borderColor: '#EBFAF7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    width: '30px',
  },

  parentwrap: {
    height: '88vh',
    backgroundColor: '#EBFAF7',
    borderRadius: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
  },
  responsiveparentwrap: {
    // height: '38vh',
    backgroundColor: '#EBFAF7',
    borderRadius: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
  },

  profileSx: {
    fontSize: '46px',
    color: 'primary.main',
    backgroundColor: '#fff',
    width: '200px',
    height: '200px',
    boxShadow: '0px 0px 0px 0px #000',
    border: '8px solid #fff',
    objectFit: 'container',
  },
  responsiveprofileSx: {
    fontSize: '46px',
    color: 'primary.main',
    backgroundColor: '#fff',
    width: '100px',
    height: '100px',
    boxShadow: '0px 0px 0px 0px #000',
    border: '8px solid #fff',
    objectFit: 'container',
  },
  emailSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 3,
  },

  editSx: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'common.black',
  },
  editdesSx: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'common.black',
    width: '220px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  contactIcon: {
    my: 3.5,
  },
  iconSx: {
    backgroundColor: 'common.white',
    border: '1px dashed',
    borderRadius: '50px',
    p: 1,
    mr: 2.75,
    width: '40px',
    height: '40px',
    borderColor: 'primary.main',
  },
  detailIconSx: {
    width: '22px',
    height: '24px',
    color: 'common.black',
  },
  detailIcon: {
    color: 'common.black',
  },

  buttonSx: {
    bgcolor: '#DFF7F3',
    color: 'primary.main',
    '&:hover': {
      bgcolor: '#DFF7F3',
    },
  },
  logoutConfirmSx: {
    textAlign: 'Center',
    py: 4.5,
  },
  callButtonSx: {
    borderRadius: 2,
    bgcolor: 'error.main',
    '&:hover': {
      bgcolor: 'error.main',
    },
  },
  drawerRootSx: {
    py: 1,
  },
  googleconnectTextSx: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 500,
    color: '#007965',
    flexGrow: 1,
  },
  googleconnectActiveTextSx: {
    color: '#0E1824',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: 500,
    flexGrow: 1,
  },
  GoogleConnectSx: {
    backgroundColor: '#FFFFFF ',
    cursor: 'pointer',
    border: ' 2px solid #DBEAE8',
    borderRadius: '8px',
    p: 1,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: 1,
  },
  GoogleConnectActiveSx: {
    backgroundColor: '#FFFFFF ',
    cursor: 'pointer',
    border: '2px solid #DBEAE8',
    alignItems: 'center',
    borderRadius: '8px',
    p: 1,
    display: 'flex',
    width: 'fit-content',
    gap: 1,
  },
  lastseenContainerSx: {
    display: 'flex',
    backgroundColor: '#FFFFFF ',
    mt: 1,
    borderRadius: '4px',
    width: 'fit-content',
    pl: 1,
    pr: 1,
    pt: 0.3,
    pb: 0.3,
    ml: 1,
  },
  synctitleSx: {
    color: '#4D4D4D',
    fontWeight: 400,
    fontSize: '10px',
    pr: 0.5,
  },
  synctimeSx: {
    color: '#0E1824',
    fontWeight: 500,
    fontSize: '10px',
  },
  centerSx: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
