/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import { doctorRoutes } from '@hc/routes';
import { useAvailability, useViewPort } from '@hc/store';
import { Button, Loader } from '@hc/ui/atoms';
import {
  DateOverrideList,
  DayAvailabilityList,
  DoctorScreenLayout,
  FilterTab,
  Screenlayout
} from '@hc/ui/components';
import { Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { doctor_availability_style } from './style';

export default function DoctorAvailability(props) {
  const { className = '' } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const md = useMediaQuery('(max-width:864px)');
  const [tabIndex, setTabIndex] = useState(0);
  const filterList = ['Working Hours', 'Date Override'];

  // General hooks
  const {
    overrideDates,
    weeklyAvailabilities,
    getAvailability,
    setAvailability,
    weeklyAvailabilityCopy,
    loading,
  } = useAvailability(
    (state) => ({
      weeklyAvailabilityCopy: state.weeklyAvailabilitiesCopy,
      loading: state.loading,
      setAvailability: state.setAvailability,
      overrideDates: state.overrideDates,
      weeklyAvailabilities: state.weeklyAvailabilities,
      getAvailability: state.getAvailability,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    }
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const update = async () => {
    let parentIndex = true;
    let weeklyAvailabilitiesCopy = JSON.parse(
      JSON.stringify(weeklyAvailabilities)
    );
    for (const file of weeklyAvailabilitiesCopy) {
      for (const data of file?.slot) {
        if (data?.fromTime === '' || data?.toTime === '') {
          parentIndex = false;
        }
      }
    }

    if (parentIndex) {
      const statusCode = await setAvailability();
      if (
        statusCode === '200' &&
        searchParams.get('verificationDone') === 'true'
      ) {
        navigate(doctorRoutes?.home);
      }
    } else {
      toast.error('Please enter the correct from and to times..!!');
    }
  };

  const onUpdate = () => {
    const data = localStorage.getItem('overRideDataCopy');
    const jsonData = JSON.parse(data);
    if (
      isEqual(weeklyAvailabilities, weeklyAvailabilityCopy) &&
      searchParams.get('verificationDone') !== 'true'
    ) {
      if (isEqual(overrideDates, jsonData)) {
        toast('Please change the any from and to times to proceed..!!', {
          icon: '⚠️',
        });
      } else {
        update();
      }
    } else {
      update();
    }
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getInitialData = async () => {
    await getAvailability();
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      {!isMobilePort && (
        <DoctorScreenLayout
          titleRequired
          title="Availability"
          className={`class ${className}`}
          backRequired={
            searchParams.get('verificationDone') === 'true' ? false : true
          }
          appbarRightButtonRequired
          rightbtn={doctor_availability_style.buttonSx}
          appbarRightButtonName="update"
          onClick={onUpdate}
          titleStyle={doctor_availability_style.titleSx}
          backIconStyle={doctor_availability_style.backIconSx}
          rightSideContainer={md ? 12 : 5}
          leftSideContainer={md ? 12 : 7}
          leftChildren={
            <Box px={1.5}>
              <Box display="flex" justifyContent="center">
                {loading && <Loader sx={{ mt: 25 }} />}
              </Box>
              <Box sx={loading ? { display: 'none' } : ''}>
                <DayAvailabilityList data={weeklyAvailabilities} />
              </Box>
            </Box>
          }
          rightChildren={
            <Box pl={1.5}>
              <Box display="flex" justifyContent="center">
                {loading && <Loader sx={{ mt: 25 }} />}
              </Box>
              <Box sx={loading ? { display: 'none' } : ''}>
                <DateOverrideList data={overrideDates} />
              </Box>
            </Box>
          }
        />
      )}
      {isMobilePort && (
        <Screenlayout
          title="Set availability"
          className={`${className}`}
          backRequired
          childrenStyle={{
            minHeight: '60vh',
            pt: {
              xs: 10,
            },
          }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={onUpdate}
                buttonStyle={doctor_availability_style.buttonSx}
              >
                update
              </Button>
            </Box>
          }
        >
          {/* Symptoms */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={doctor_availability_style.boxRootSx}>
              <Box
                sx={{
                  backgroundColor: '#EDEFF2',
                  borderRadius: '10px',
                  height: '44px',
                  width: '290px',
                }}
              >
                <FilterTab
                  filterList={filterList}
                  handleChange={handleChange}
                  value={tabIndex}
                  toggleStyle={{ height: '44px' }}
                  toggleContainerSx={{
                    justifyContent: '',
                    width: '300px',
                  }}
                  filterTabRootStyle={doctor_availability_style.filterTabRootSx}
                />
              </Box>
            </Box>
          </Box>
          {tabIndex === 0 && (
            <Box px={1.5}>
              <Box display="flex" justifyContent="center">
                {loading && <Loader sx={{ mt: 25 }} />}
              </Box>
              <Box sx={loading ? { display: 'none' } : ''}>
                <DayAvailabilityList data={weeklyAvailabilities} />
              </Box>
            </Box>
          )}
          {tabIndex === 1 && (
            <Box px={2} pt={1}>
              <Box display="flex" justifyContent="center">
                {loading && <Loader sx={{ mt: 25 }} />}
              </Box>
              <Box sx={loading ? { display: 'none' } : ''}>
                <DateOverrideList data={overrideDates} />
              </Box>
            </Box>
          )}
        </Screenlayout>
      )}
    </>
  );
}

DoctorAvailability.propTypes = {
  className: PropTypes.string,
};
