import { useViewPort } from '@hc/store';
import {
  Button,
  ConnectIcon,
  DoctorBookAppointmentIcon,
  DocumentIcon,
  PhoneIcon,
  UserProfileIcon,
} from '@hc/ui/atoms';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { patientCard_style } from './style';

function PatientCard(props) {
  const {
    className = '',
    rootStyle = {},
    PatientName = 'Arunachalam R',
    subText = 'male',
    age = '24',
    mobile = ' +91 9992299999',
    mobilePic,
    countyCode,
    userPic = false,
    border = true,
    dashed,
    onView = () => false,
    onAppointmnet = () => false,
    onRefer = () => false,
    index,
    ...rest
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box
      sx={{
        ...patientCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={patientCard_style.totalBoxSx} id={`patientCard${index + 1}`}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{
            ...patientCard_style.gridcenterTotal,
            borderBottom:
              !isMobilePort && border ? '0.5px dashed #DBDBDB ' : '',
            border: isMobilePort && '1px solid #E2E2E2',
            borderRadius: isMobilePort && '6px',
          }}
          p={isMobilePort ? 1 : null}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            pt={1}
            sx={patientCard_style.gridcenter}
          >
            <Box sx={patientCard_style.totalCardLeftSx}>
              <Grid container>
                <Grid item xs={3}>
                  <Avatar aria-label="recipe" sx={patientCard_style.avatar}>
                    {userPic ? (
                      <img src={userPic} alt="" />
                    ) : (
                      <UserProfileIcon rootStyle={{ color: 'common.black' }} />
                    )}
                  </Avatar>
                </Grid>
                <Grid item xs={9} display="flex">
                  <Grid container alignSelf="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {PatientName}
                      </Typography>
                    </Grid>
                    {subText && (
                      <Grid item xs={12} display="flex" justifyContent="start">
                        <Box component="span" style={patientCard_style.subText}>
                          {subText}
                          {age && (
                            <Box
                              component="span"
                              style={patientCard_style.subText}
                            >
                              ,{age} Yrs
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {!isMobilePort && (
                <Grid container>
                  <Grid item xs={3}>
                    <Avatar aria-label="recipe" sx={patientCard_style.avatar}>
                      <PhoneIcon rootStyle={{ color: '#000' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={9} display="flex">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      alignSelf="center"
                      sx={{ fontWeight: 500 }}
                    >
                      {countyCode} {mobile}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={patientCard_style.gridcenter}>
            <Grid container px={1}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    backgroundColor: isMobilePort ? '#fff5ef' : '',
                    width: '46px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                  }}
                >
                  <Tooltip title="View Summary" arrow>
                    <IconButton
                      id={`summary${index + 1}`}
                      onClick={onView}
                      disableRipple
                      sx={patientCard_style.viewSummarySx}
                    >
                      <DocumentIcon rootStyle={patientCard_style.documentSx} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    backgroundColor: isMobilePort ? '#DCF5F1' : '',
                    width: '46px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                  }}
                >
                  <Tooltip title="Book Appointment" arrow>
                    <IconButton
                      id={`appointment${index + 1}`}
                      onClick={onAppointmnet}
                    >
                      <DoctorBookAppointmentIcon rootStyle={{}} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button
                  id={`refer${index + 1}`}
                  buttonStyle={patientCard_style.referCardSx}
                  onClick={onRefer}
                >
                  <ConnectIcon
                    rootStyle={{ pr: 1, width: '23px', height: '23px' }}
                  />
                  Refer
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

PatientCard.propTypes = {
  PatientName: PropTypes.string,
  age: PropTypes.string,
  subText: PropTypes.string,
  mobile: PropTypes.string,
  mobilePic: PropTypes.string,
  userPic: PropTypes.string,
  dashed: PropTypes.bool,
  border: PropTypes.bool,
  onView: PropTypes.func,
  onAppointmnet: PropTypes.func,
  onRefer: PropTypes.func,
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  countyCode: PropTypes.object,
  index: PropTypes.any,
};

export { PatientCard };
