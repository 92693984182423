import { doctorRoutes } from '@hc/routes';
import { PageNotFound } from '@hc/ui/components';
import { DoctorRootLayout } from '@hc/ui/components/doctor';
import Summary from '@pages//summary';
import { DoctorOnboarding } from '@pages/createAccount';
import DoctorAvailability from '@pages/doctorAvailability';
import ErrorBoundary from '@pages/errorBoundary';
import ForgotPassword from '@pages/forgotpassword';
import GoogleIntegeration from '@pages/googleCalenderIntegeration';
import Home from '@pages/home';
import Onboarding from '@pages/onboarding';
import Prescription from '@pages/Prescription';
import DoctorProfile from '@pages/profile';
import ReportViewSummary from '@pages/reportViewSummary';
import ResetPassword from '@pages/resetPassword';
import RxGroup from '@pages/rxGroup';
import SignIn from '@pages/signin';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReferralSuccess from '../pages/referralSuccess';
import { PrivateRouter } from './privateRouter';
import ViewDrug from '../pages/rxGroup/viewDrug';
import ViewReportPage from '../pages/reportViewSummary/ViewSummaryReports';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRouter>
        <DoctorRootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      // onboarding
      {
        path: doctorRoutes?.createaccount,
        element: <DoctorOnboarding />,
      },
      {
        path: doctorRoutes?.signin,
        element: <SignIn />,
      },
      {
        path: doctorRoutes?.onboarding,
        element: <Onboarding />,
      },
      {
        path: doctorRoutes?.forgotpassword,
        element: <ForgotPassword />,
      },
      {
        path: doctorRoutes?.resetpassword,
        element: <ResetPassword />,
      },
      {
        path: doctorRoutes?.rxgroup,
        element: <RxGroup />,
      },
      {
        path: doctorRoutes?.viewDrug,
        element: <ViewDrug />,
      },
      {
        path: doctorRoutes?.profile,
        element: <DoctorProfile />,
      },
      {
        path: doctorRoutes?.reportviewsummary,
        element: <ReportViewSummary />,
      },
      {
        path: doctorRoutes?.viewReportPage,
        element: <ViewReportPage />,
      },
      {
        path: doctorRoutes?.summary,
        element: <Summary />,
      },
      {
        path: doctorRoutes?.availability,
        element: <DoctorAvailability />,
      },
      {
        path: doctorRoutes?.home,
        element: <Home />,
      },

      {
        path: doctorRoutes?.googleintegeration,
        element: <GoogleIntegeration />,
      },
      {
        path: doctorRoutes?.referralsuccess,
        element: <ReferralSuccess />,
      },
      {
        path: doctorRoutes?.Prescription,
        element: <Prescription />,
      },
    ],
  },
  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;
