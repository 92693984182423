import { useDoctorOnboarding, useViewPort } from '@hc/store';
import DoctorOnboarding from '@hc/ui/assets/doctorOnboarding.png';
import { Button, HealthCirclesLogo, Input, Label } from '@hc/ui/atoms';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { signIn_style } from './style';

export default function SignIn(props) {
  const { className = '', ...rest } = props;

  const {
    doctor,
    updateStateOnBoarding,
    updateOnboarding,
    updateErrorOnboarding,
    signIn,
    loading,
  } = useDoctorOnboarding(
    (state) => ({
      doctor: state.doctor,
      signIn: state.signIn,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
      updateErrorOnboarding: state.updateErrorOnboarding,
      updateStateOnBoarding: state.updateStateOnBoarding,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  // General Hooks
  // const [data, setData] = useState(doctor)
  const navigate = useNavigate();
  const [showpassword, setPassword] = useState();
  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };

  const handleChange = (val, key) => {
    updateOnboarding(key, val);
  };

  // Validation
  const isIamValideToSignIn = () => {
    let isValid = true;
    const error = doctor?.error;

    //  Checking email
    if (doctor?.email.length === 0) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else if (doctor?.email.length < 10) {
      isValid = false;
      error.email = 'Enter a valid email';
    } else {
      error.email = '';
    }

    // Checking password
    if (doctor?.password.length === 0) {
      isValid = false;
      error.password = 'Enter password';
    } else {
      error.password = '';
    }
    updateErrorOnboarding(error);
    return isValid;
  };

  const onSignIn = async () => {
    if (isIamValideToSignIn()) {
      const error = doctor?.error;
      error.password = '';
      error.email = '';
      updateErrorOnboarding(error);
      // calling the signup api
      signIn(doctor);
    }
  };

  useEffect(() => {
    updateStateOnBoarding();
  }, []);

  const handleClick = () => {
    navigate('/forgotpassword');
  };

  return (
    <Box sx={signIn_style.rootSx} className={`${className}`} {...rest}>
      <Grid container sx={signIn_style.totalSx}>
        {/* Right Side */}
        <Grid item sm={isMobilePort ? 12 : 6} sx={signIn_style.leftSideSx}>
          <Box sx={isMobilePort ? { background: '#000' } : ''}>
            <img
              src={DoctorOnboarding}
              alt=""
              style={
                isMobilePort
                  ? signIn_style.rightImgMobileSx
                  : signIn_style.rightImgSx
              }
            />
          </Box>
        </Grid>
        {/* Left Side */}
        <Grid
          item
          xs={isMobilePort ? 12 : 6}
          sm={isMobilePort ? 12 : 6}
          sx={signIn_style.rightSideSx}
        >
          {/* <Box width={425}> */}
          <Box
            sx={
              isMobilePort
                ? signIn_style.CardcontentMobileSx
                : signIn_style.CardcontentSx
            }
          >
            <Box display="flex" gap={0.5}>
              <HealthCirclesLogo />
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Health Circles
              </Typography>
            </Box>
            <Typography sx={signIn_style.createPasswordSx}>Welcome</Typography>
            <Box>
              <Label htmlFor="emailInput">Email</Label>
              <Input
                value={doctor?.email ?? ''}
                id="email"
                isError={doctor?.error.email}
                helperText={doctor?.error.email}
                onChange={(value) => handleChange(value.target.value, 'email')}
              />
            </Box>
            <Box sx={signIn_style.inputGroupSx}>
              <Label htmlFor="password" isRequired>
                password
              </Label>
              <Input
                id="password"
                type={showpassword ? 'text' : 'password'}
                onChange={(e) => handleChange(e.target.value, 'password')}
                isError={doctor?.error.password}
                errorMessage={doctor?.error.password}
                value={doctor?.password ?? ''}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    edge="end"
                  >
                    {showpassword ? (
                      <MdVisibilityOff
                        htmlColor="#848484"
                        sx={signIn_style.eyeSx}
                      />
                    ) : (
                      <MdVisibility
                        htmlColor="#848484"
                        sx={signIn_style.eyeSx}
                      />
                    )}
                  </IconButton>
                }
              />
              <Typography
                sx={signIn_style.forgotPasswordTextSx}
                onClick={handleClick}
              >
                {' '}
                Forgot Password?
              </Typography>
            </Box>
            <Box sx={{ mt: 3, display: 'grid', gap: 3 }}>
              <Button
                id="login"
                fullWidth
                buttonStyle={signIn_style.buttonStyleSx}
                onClick={() => onSignIn()}
                loading={loading}
              >
                login
              </Button>
            </Box>
          </Box>
          {/* </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
}


SignIn.propTypes = {
  className: PropTypes.string,
};
