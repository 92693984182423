/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { useDoctorProfile, useViewPort } from '@hc/store';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { Button } from '../../../atoms';
import { stepperForm_style } from './style';

export function DoctorTitleCard(props) {
  const {
    activeIndex,
    disableBackBtn,
    activeBack,
    disableNextBtn,
    description = '',
    rootStyle = {},
    nextStyle = {},
    activeNext,
    title = '',
    onNext = () => false,
    onBack = () => false,
    className = '',
    ...rest
  } = props;

  const { loading } = useDoctorProfile((state) => ({
    loading: state.loading,
  }));

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...stepperForm_style.rootSx, ...rootStyle }} container>
        <Box sx={{ ...stepperForm_style.nextSx, ...nextStyle }}>
          <Grid container>
            <Grid
              item
              sm={isMobilePort ? 12 : 4}
              md={isMobilePort ? 12 : 4}
              lg={isMobilePort ? 12 : 6}
            >
              <Box>
                <Typography sx={{ ...stepperForm_style.rightTextSx }}>
                  {title}
                </Typography>
                <Typography sx={{ ...stepperForm_style.rightSubTextSx }}>
                  {description}
                </Typography>
              </Box>
            </Grid>
            {!isMobilePort && (
              <Grid
                item
                display="flex"
                justifyContent="end"
                sm={8}
                md={8}
                lg={6}
              >
                <Box>
                  {activeBack && (
                    <IconButton
                      sx={stepperForm_style.iconNextSx}
                      disabled={disableBackBtn}
                      disableRipple
                      onClick={onBack}
                    >
                      <BiChevronLeft
                        style={{
                          color: disableBackBtn ? 'disabled' : '#0F0B117A',
                          fontSize: '25px',
                        }}
                      />
                      BACK
                    </IconButton>
                  )}
                  {activeNext && (
                    <IconButton
                      sx={{ ...stepperForm_style.iconNextSx, color: '#007965' }}
                      disabled={disableNextBtn}
                      disableRipple
                      onClick={() => onNext(activeIndex)}
                    >
                      {activeIndex === 2 ? (
                        loading ? (
                          <Button
                            loading
                            buttonStyle={{
                              '&.MuiLoadingButton-loading': {
                                backgroundColor: '#fff',
                              },
                            }}
                          >
                            {' '}
                            SUBMIT
                          </Button>
                        ) : (
                          'SUBMIT'
                        )
                      ) : (
                        'NEXT'
                      )}
                      <BiChevronRight
                        style={{
                          color: disableNextBtn ? 'disabled' : '#007965',
                          fontSize: '25px',
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>

          {/* Next and Back Function  */}
        </Box>
      </Box>
    </Box>
  );
}
DoctorTitleCard.propTypes = {
  StepperFormData: PropTypes.array,
  activeIndex: PropTypes.func,
  disableBackBtn: PropTypes.func,
  disableNextBtn: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
