import { useRouting, useViewPort } from '@hc/store';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';
import { rootLayout_style } from './style';

export function DoctorRootLayout(props) {
  const {
    className = '',
    children,
    backgroundStyle,
    rootStyle,
    ...rest
  } = props;

  const { setValue } = useViewPort((state) => ({
    setValue: state.setValue,
  }));

  const navigate = useNavigate();
  const route = useRouting((state) => state.route);
  const [isMobile, setIsmobile] = useState();

  // ------------Media query ------------------
  const mediaQueryList = window.matchMedia('(max-width: 768px)');
  mediaQueryList.addEventListener('change', () => {
    if (mediaQueryList.matches) {
      setIsmobile(true);
      // localStorage.setItem(localStorageKeys?.isMobilePort, true);
      setValue('isMobilePort', true);
    } else {
      setIsmobile(false);
      // localStorage.setItem(localStorageKeys?.isMobilePort, false);
      setValue("isMobilePort",false);
    }
  });

  useEffect(() => {
    if (route !== null) {
      navigate(route);
      setTimeout(() => {
        useRouting.setState({ route: null });
      }, 1000);
    }
  }, [route]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setValue("isMobilePort",true);
    } else {
      setValue("isMobilePort",false);
    }
  }, []);

  return (
    <Box
      sx={{ ...rootLayout_style.backgroundSx, ...backgroundStyle }}
      className={`${className}`}
      {...rest}
    >
      <Toaster />
      <Box
        sx={
          isMobile
            ? { ...rootLayout_style.mobileRootSx, ...rootStyle }
            : { ...rootLayout_style.rootSx, ...rootStyle }
        }
      >
        <Outlet />
      </Box>
    </Box>
  );
}
